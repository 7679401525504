import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const UserSalesReport = () => {
  return (
    <DashboardLayout>
      <div>UserSalesReport</div>
    </DashboardLayout>
  );
};

export default UserSalesReport;

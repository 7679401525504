import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const NewExpense = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>NewExpense</div>
    </DashboardLayout>
  );
};

export default NewExpense;

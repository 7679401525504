import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const SalesList = () => {
  return (
    <DashboardLayout>
      <div>SalesList</div>
    </DashboardLayout>
  );
};

export default SalesList;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const RoleList = () => {
  return (
    <DashboardLayout>
      <div>RoleList</div>
    </DashboardLayout>
  );
};

export default RoleList;

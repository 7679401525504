import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const CategoryList = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>CategoryList</div>
    </DashboardLayout>
  );
};

export default CategoryList;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const PurchaseReturnList = () => {
  return (
    <DashboardLayout>
      <div>PurchaseReturnList</div>
    </DashboardLayout>
  );
};

export default PurchaseReturnList;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const SMSAPI = () => {
  return (
    <DashboardLayout>
      <div>SMSAPI</div>
    </DashboardLayout>
  );
};

export default SMSAPI;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const ProfitLossReport = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>ProfitLossReport</div>
    </DashboardLayout>
  );
};

export default ProfitLossReport;

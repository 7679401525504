import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const ProductSalesReport = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>ProductSalesReport</div>
    </DashboardLayout>
  );
};

export default ProductSalesReport;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const NewSale = () => {
  return (
    <DashboardLayout>
      <div>NewSale</div>
    </DashboardLayout>
  );
};

export default NewSale;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const UnitsList = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>UnitList</div>
    </DashboardLayout>
  );
};

export default UnitsList;

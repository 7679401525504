import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const CurrencyList = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>CurrencyList</div>
    </DashboardLayout>
  );
};

export default CurrencyList;

import React from "react";

const StockAlert = () => {
  const stockItems = [
    { name: "Ace", stock: 0 },
    { name: "Adsalt", stock: 0 },
    { name: "Alacot® DS", stock: 0 },
    { name: "Alatrol®", stock: 0 },
    { name: "Alatrol®", stock: 0 },
  ];

  return (
    <div className="w-full">
      <div className="bg-primary text-white text-center py-3 rounded-tl-3xl rounded-br-3xl rounded w-full">
        <h2 className="font-semibold">Stock Alert</h2>
      </div>
      <table className="min-w-full m-3">
        <thead>
          <tr className="text-left  text-secondary font-semibold">
            <th className="pb-2">Medicine Name</th>
            <th className="pb-2">Stock</th>
          </tr>
        </thead>
        <tbody>
          {stockItems.map((item, index) => (
            <tr key={index} className="text-sm">
              <td className="py-1">{item.name}</td>
              <td className="py-1">{item.stock}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center pt-5">
        <a href="#" className="font-semibold text-secondary hover:text-primary">
          View All
        </a>
      </div>
    </div>
  );
};

export default StockAlert;

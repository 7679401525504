import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import Loader from "../../components/Loader";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoIosCopy } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdReceipt } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaFileExcel } from "react-icons/fa6";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { IoIosPrint } from "react-icons/io";
import { FaFileCsv } from "react-icons/fa";
import { HiMiniViewColumns } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import copy from "copy-to-clipboard";
import Swal from "sweetalert2";
import defaultImage from "../../assets/no-image.png";
import { fetchMedicines, editMedicine, deleteMedicine } from "../../api/api";

// PopupReceive component

const MedicineList = () => {
  useEffect(() => {
    document.title = "Medicine List";
  }, []);
  const [actionDropdowns, setActionDropdowns] = useState({});

  const [activeMedicine, setActiveMedicine] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [checkedItems, setCheckedItems] = useState({});
  const [isColumnsDropdownOpen, setIsColumnsDropdownOpen] = useState(false);
  // fetch Medicine
  const [medicines, setMedicines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const tableRef = useRef();
  const columnsRef = useRef();

  const navigate = useNavigate(); // Use useNavigate

  const handleEditMedicine = (medicine) => {
    // Navigate to the EditMedicine page and pass the Medicine data
    navigate(`/editmedicine/${medicine.id}`, { state: { medicine } });
  };

  const handleToggleStatus = async (medicineId, currentStatus) => {
    const updatedStatus = currentStatus === 1 ? 0 : 1; // Toggle status

    // Optimistically update the UI before the server responds
    setMedicines((prevMedicines) =>
      prevMedicines.map((medicine) =>
        medicine.id === medicineId
          ? { ...medicine, status: updatedStatus }
          : medicine
      )
    );

    try {
      const response = await editMedicine(medicineId, {
        status: updatedStatus,
      });

      // Log the server response for debugging
      console.log("Server response:", response);

      // Optionally, check the server's response and log any issues
      if (
        response &&
        response.medicine &&
        response.medicine.status !== updatedStatus
      ) {
        console.error("Server did not update the status as expected.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      // Revert the UI change if the request fails
      setMedicines((prevMedicines) =>
        prevMedicines.map((medicine) =>
          medicine.id === medicineId
            ? { ...medicine, status: currentStatus }
            : medicine
        )
      );
    }
  };

  const toggleActionDropdown = (medicineId) => {
    setActionDropdowns((prev) => {
      // Check if the dropdown for the clicked medicine is already open
      const isOpen = prev[medicineId] || false;
      // Close all dropdowns and open the one that was clicked
      return { [medicineId]: !isOpen };
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (columnsRef.current && !columnsRef.current.contains(event.target)) {
        setIsColumnsDropdownOpen(false);
      }

      if (!event.target.closest(".action-dropdown")) {
        setActionDropdowns({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Search Functionality
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Execute the search filtering here
    }, 300); // 300ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // Pagination Logic

  const filteredMedicines = medicines.filter((medicine) => {
    const searchTermLower = searchTerm.toLowerCase();

    return (
      (medicine.name &&
        medicine.name.toLowerCase().includes(searchTermLower)) ||
      (medicine.id &&
        String(medicine.id).toLowerCase().includes(searchTermLower)) ||
      (medicine.generic_name &&
        medicine.generic_name.toLowerCase().includes(searchTermLower)) ||
      (medicine.medicine_type &&
        medicine.medicine_type.toLowerCase().includes(searchTermLower)) ||
      (medicine.supplier_name &&
        medicine.supplier_name.toLowerCase().includes(searchTermLower)) ||
      (medicine.shelf &&
        medicine.shelf.toLowerCase().includes(searchTermLower)) ||
      (medicine.price &&
        String(medicine.price).toLowerCase().includes(searchTermLower)) ||
      (medicine.supplier_price &&
        String(medicine.supplier_price)
          .toLowerCase()
          .includes(searchTermLower)) ||
      (medicine.strength &&
        medicine.strength.toLowerCase().includes(searchTermLower))
    );
  });

  const totalPages = Math.ceil(filteredMedicines.length / itemsPerPage);
  const paginatedMedicines = filteredMedicines.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Generate page numbers with ellipsis logic
  const generatePageNumbers = () => {
    const pageNumbers = [];

    // Always show the first page
    pageNumbers.push(1);

    // Check if there are more than two pages
    if (totalPages > 2) {
      // Add ellipses if current page is more than 1 and less than totalPages
      if (currentPage > 1 && currentPage < totalPages) {
        pageNumbers.push("...");
      }

      // Add the current page only if it's not the first or last
      if (currentPage > 1 && currentPage < totalPages) {
        pageNumbers.push(currentPage);
      }

      // Always show the last page if it's not already shown
      if (currentPage < totalPages) {
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  //   // Fetch medicines when the component mounts
  useEffect(() => {
    const getMedicines = async () => {
      try {
        setLoading(true); // Set loading to true before fetching
        const medicineData = await fetchMedicines();
        // Sort medicinets by 'created_at' in descending order (newest first)
        const sortedMedicines = medicineData.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setMedicines(medicineData); // Set the medicines into state
      } catch (err) {
        setError(err); // Handle the error
      } finally {
        setLoading(false); // Stop the loading spinner
      }
    };

    getMedicines(); // Trigger the fetch
  }, []);

  const handleDeleteMedicine = async () => {
    // Convert selectedMedicineIds to numbers
    const selectedMedicineIds = Object.keys(checkedItems)
      .filter((id) => checkedItems[id])
      .map(Number); // Ensure IDs are numbers

    if (selectedMedicineIds.length === 0) {
      Swal.fire(
        "No Selection",
        "Please select at least one medicine to delete.",
        "info"
      );
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text:
        selectedMedicineIds.length === 1
          ? "Do you want to delete this medicine?"
          : `Do you want to delete ${selectedMedicineIds.length} medicines?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Show a loading indicator
          Swal.fire({
            title: "Deleting...",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          // Perform deletion for each selected medicine
          const deletePromises = selectedMedicineIds.map((id) =>
            deleteMedicine(id)
          );

          await Promise.all(deletePromises);

          // After successful deletion, show success message
          Swal.fire(
            "Deleted!",
            selectedMedicineIds.length === 1
              ? "The selected medicine has been deleted."
              : "The selected medicines have been deleted.",
            "success"
          );

          // ** Update the medicines state by removing deleted medicines **
          setMedicines((prevMedicines) => {
            return prevMedicines.filter(
              (medicine) => !selectedMedicineIds.includes(medicine.id)
            );
          });

          // Clear checked items after deletion
          setCheckedItems((prevChecked) => {
            const updatedChecked = { ...prevChecked };
            selectedMedicineIds.forEach((id) => {
              delete updatedChecked[id];
            });
            return updatedChecked;
          });
        } catch (error) {
          console.error("Deletion error:", error);
          Swal.fire(
            "Error!",
            "There was an error deleting the selected medicines.",
            "error"
          );
        }
      }
    });
  };

  const handleDeleteSingleMedicine = async (medicineId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this medicine?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Show a loading indicator
          Swal.fire({
            title: "Deleting...",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          await deleteMedicine(medicineId);

          // After successful deletion, show success message
          Swal.fire(
            "Deleted!",
            "The selected medicine has been deleted.",
            "success"
          );

          // Update the medicines state by removing the deleted medicine
          setMedicines((prevMedicines) =>
            prevMedicines.filter((medicine) => medicine.id !== medicineId)
          );

          // Remove the medicine from checkedItems if it was checked
          setCheckedItems((prevChecked) => {
            const updatedChecked = { ...prevChecked };
            delete updatedChecked[medicineId];
            return updatedChecked;
          });
        } catch (error) {
          console.error("Deletion error:", error);
          Swal.fire(
            "Error!",
            "There was an error deleting the selected medicine.",
            "error"
          );
        }
      }
    });
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    const newCheckedItems = {};
    medicines.forEach((medicine) => {
      newCheckedItems[medicine.id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  const handleIndividualCheckbox = (medicineId) => {
    setCheckedItems((prevChecked) => ({
      ...prevChecked,
      [medicineId]: !prevChecked[medicineId],
    }));
  };

  const isDeleteButtonVisible = Object.values(checkedItems).some(Boolean);
  // If loading, show a loading message
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  // If there is an error, display the error message
  if (error) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-primary">Medicine List</h2>
          <div>
            <Link to="/addmedicine">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <BsFillPlusCircleFill />
                </span>
                Add Medicine
              </p>
            </Link>
          </div>
        </div>
        {/* Table Controls */}
        <div className="flex justify-between items-center mb-4 dropdown">
          <div className="flex justify-between items-center gap-3 font-semibold text-primary">
            <p>Show</p>
            <select
              className="flex  items-center border custom-border w-24 px-1 py-1 focus:outline-none cursor-pointer"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange} // Made dynamic
            >
              <option
                value={5}
                className="hover:bg-white cursor-pointer custom-border"
              >
                5
              </option>
              <option
                value={10}
                className="hover:bg-white cursor-pointer custom-border"
              >
                10
              </option>
              <option
                value={15}
                className="hover:bg-white cursor-pointer custom-border"
              >
                15
              </option>
            </select>
          </div>
          <div className="w-24">
            {isDeleteButtonVisible && (
              <div
                className="flex justify-between text-center items-center bg-red-600 custom-border px-2 py-1"
                onClick={handleDeleteMedicine}
              >
                <button className=" text-white hover:text-gray-300 py-1 px-2 cursor-pointer  flex items-center gap-1">
                  Delete{" "}
                  <span>
                    <RiDeleteBin6Fill />
                  </span>
                </button>
              </div>
            )}
          </div>

          <div className="flex justify-between text-center items-center bg-primary custom-border px-2 py-1">
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              Copy{" "}
              <span>
                <IoIosCopy />
              </span>
            </button>
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              Excel{" "}
              <span>
                <FaFileExcel />
              </span>
            </button>
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              PDF{" "}
              <span>
                <BsFillFileEarmarkPdfFill />
              </span>
            </button>
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              Print{" "}
              <span>
                <IoIosPrint />
              </span>
            </button>

            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              CSV{" "}
              <span>
                <FaFileCsv />
              </span>
            </button>

            <button
              ref={columnsRef}
              className="relative  text-white  py-1 px-2 cursor-pointer flex items-center gap-1 "
            >
              <span className="hover:text-secondary">Columns</span>
              <span>
                <HiMiniViewColumns />
              </span>
            </button>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="border custom-border bg-gray-300 text-primary font-semibold rounded-md p-2 focus:outline-none pl-8 focus:ring-0 focus:ring-blue-500"
              value={searchTerm}
              onChange={handleSearch}
            />
            <span className="absolute left-2 top-3 text-xl text-primary font-bold">
              <CiSearch />
            </span>
          </div>
        </div>
        {/* Table */}
        <table
          ref={tableRef}
          className="bg-white border border-gray-200 w-full"
        >
          <thead>
            <tr className="bg-primary text-white text-sm">
              <th className="py-1 px-2 border-r w-6 ">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={
                    medicines.length > 0 &&
                    Object.keys(checkedItems).length === medicines.length &&
                    Object.values(checkedItems).every(Boolean)
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th className="py-1 px-2 border-r">SL</th>

              <th className="p-1 border-r">Medicine Name</th>

              <th className="p-1 border-r break-all">Generic Name</th>

              <th className="p-1 border-r ">Medicine Type</th>

              <th className="p-1 border-r break-all">Supplier</th>

              <th className="p-1 border-r ">Shelf</th>

              <th className="p-1 border-r text-right">Price</th>

              <th className="p-1 border-r text-right">Supplier Price</th>

              <th className="p-1 border-r text-center">Strength</th>

              <th className="p-1 border-r text-center">Image</th>
              <th className="p-1 border-r text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedMedicines.length > 0 ? (
              paginatedMedicines.map((medicine, index) => (
                <tr key={medicine.id} className="border-b">
                  <td className="py-1 px-2 text-center border-r">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={!!checkedItems[medicine.id]}
                      onChange={() => handleIndividualCheckbox(medicine.id)}
                    />
                  </td>
                  <td className="py-1 px-2 text-center text-gray-600 border-r">
                    {/* {medicine.id} */}
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>{" "}
                  <td className="p-1 border-r text-gray-600 capitalize">
                    {medicine.name}
                  </td>
                  <td className="p-1 border-r text-gray-600 capitalize">
                    {medicine.generic_name}
                  </td>
                  <td className="p-1 border-r text-gray-600 capitalize  break-all ">
                    {medicine.medicine_type}
                  </td>
                  <td className="p-1 border-r text-gray-600 capitalize break-all">
                    {medicine.supplier_name}
                  </td>
                  <td className="p-1 border-r text-gray-600 capitalize break-all">
                    {medicine.shelf}
                  </td>
                  <td className="p-1 border-r text-right text-gray-600">
                    {medicine.price}
                  </td>
                  <td className="p-1 border-r text-right text-gray-600">
                    {medicine.supplier_price}
                  </td>
                  <td className="p-1 border-r capitalize text-gray-600">
                    {medicine.strength}
                  </td>
                  <td className="p-1 text-center border-r flex justify-center">
                    <img
                      src={medicine.image_url || defaultImage}
                      alt="Medicine"
                      className="w-20 h-12 object-cover border border-secondary p-1 rounded"
                    />
                  </td>
                  <td className="p-1 text-center action-dropdown">
                    <div className="flex justify-center">
                      <div className="relative">
                        <button
                          className="bg-primary text-sm text-white py-1 px-2 gap-1 rounded-md hover:bg-secondary flex justify-center items-center"
                          onClick={() => toggleActionDropdown(medicine.id)}
                        >
                          Action
                          {actionDropdowns[medicine.id] ? (
                            <IoIosArrowUp />
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </button>
                        {actionDropdowns[medicine.id] && (
                          <div className="absolute bg-primary custom-border right-0 w-48 z-10 p-2 text-start text-white text-sm list-none">
                            <ul>
                              <li
                                className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                onClick={() => handleEditMedicine(medicine)}
                              >
                                <FaEdit /> Edit
                              </li>

                              <li
                                className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                onClick={() =>
                                  handleDeleteSingleMedicine(medicine.id)
                                }
                              >
                                <RiDeleteBin6Fill /> Delete
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="11"
                  className="py-4 px-4 text-center text-red-500 font-semibold"
                >
                  Sorry, No medicines found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex justify-between items-center mt-4 ">
          <p className="text-primary font-semibold">
            Showing{" "}
            {filteredMedicines.length > 0
              ? (currentPage - 1) * itemsPerPage + 1
              : 0}{" "}
            to{" "}
            {currentPage * itemsPerPage > filteredMedicines.length
              ? filteredMedicines.length
              : currentPage * itemsPerPage}{" "}
            of {filteredMedicines.length} entries
          </p>
          <div className="flex justify-end">
            <div className="flex justify-between items-center w-full bg-blue-200 custom-border px-4 py-1 space-x-2  mx-auto">
              {/* Previous Button */}
              <button
                className={`px-3 py-1 custom-border ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                    : "bg-blue-200 text-primary font-semibold hover:bg-blue-300"
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              {/* Generate Page Numbers with Ellipses */}
              {generatePageNumbers().map((page, index) => (
                <button
                  key={index}
                  className={`px-7 py-1 font-semibold custom-border ${
                    currentPage === page
                      ? "bg-primary text-white"
                      : "bg-blue-200 text-primary hover:bg-blue-300"
                  }`}
                  onClick={() =>
                    typeof page === "number" && handlePageChange(page)
                  }
                  disabled={typeof page !== "number"} // Disable ellipses
                >
                  {page}
                </button>
              ))}

              {/* Next Button */}
              <button
                className={`px-3 py-1 custom-border ${
                  currentPage === totalPages || totalPages === 0
                    ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                    : "bg-blue-200 text-primary font-semibold hover:bg-blue-300"
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 0}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MedicineList;

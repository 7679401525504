// import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
// import Sidebar from "./Sidebar";
// import { FaCirclePlus } from "react-icons/fa6";
// import { FaCircleMinus } from "react-icons/fa6";

// import { MdPointOfSale } from "react-icons/md";
// import { MdNotifications } from "react-icons/md";
// import { IoWarning } from "react-icons/io5";
// import { FaUserLarge } from "react-icons/fa6";

// const DashboardLayout = ({ children }) => {
//   const [currentDateTime, setCurrentDateTime] = useState("");
//   const [isBoxOpen, setIsBoxOpen] = useState(false); // State to track the visibility of the absolute box
//   const boxRef = useRef(null); // Reference to the div containing the button and absolute box

//   useEffect(() => {
//     const updateDateTime = () => {
//       const date = new Date();
//       const options = {
//         weekday: "long",
//         year: "numeric",
//         month: "long",
//         day: "numeric",
//         hour: "numeric",
//         minute: "numeric",
//         second: "numeric",
//       };
//       const formattedDateTime = date.toLocaleDateString("en-US", options);
//       setCurrentDateTime(formattedDateTime);
//     };

//     updateDateTime(); // Initial call to set date and time immediately

//     const intervalId = setInterval(updateDateTime, 1000); // Update every second
//     return () => clearInterval(intervalId); // Cleanup interval on unmount
//   }, []);

//   // Function to toggle the box and button label
//   const toggleBox = () => {
//     setIsBoxOpen(!isBoxOpen);
//   };

//   // Close the box if clicked outside of it
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (boxRef.current && !boxRef.current.contains(event.target)) {
//         setIsBoxOpen(false);
//       }
//     };

//     // Add event listener to detect clicks outside
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside); // Cleanup on unmount
//     };
//   }, [boxRef]);

//   return (
//     <div className="flex min-h-[185vh] max-h-full bg-primary ">
//       {/* Sidebar */}
//       <Sidebar className=" w-20  " />

//       <div className="flex-1 flex flex-col w-80 ">
//         {/* Main Content */}
//         <div className="flex-1 flex flex-col bg-white rounded-3xl  mt-3 mr-3 ">
//           {/* Navbar */}
//           <header className="flex justify-between items-center m-3">
//             <div className="flex justify-between items-center">
//               <div className="relative" ref={boxRef}>
//                 {/* Button that toggles between 'Add' and 'Close' */}
//                 <p
//                   className="flex items-center gap-1 py-2 px-4 bg-primary text-white rounded-tl-2xl rounded-br-2xl rounded cursor-pointer "
//                   onClick={toggleBox}
//                 >
//                   Add New
//                   <span>
//                     {isBoxOpen ? <FaCircleMinus /> : <FaCirclePlus />}
//                   </span>
//                 </p>

//                 {/* Absolute box, visible only when `isBoxOpen` is true */}
//                 {isBoxOpen && (
//                   <div className="absolute w-auto py-2 px-4 text-center font-semibold bg-primary text-white rounded-tl-2xl rounded-br-2xl rounded z-10">
//                     <Link to="/addmedicine">
//                       <p
//                         className="px-2 py-[.1em] mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
//                         onClick={toggleBox}
//                       >
//                         {" "}
//                         Medicine
//                       </p>
//                     </Link>
//                     <Link to="/addpurchase">
//                       {" "}
//                       <p
//                         className="px-2 py-[.1em] mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
//                         onClick={toggleBox}
//                       >
//                         {" "}
//                         Purchase
//                       </p>
//                     </Link>
//                     <Link to="/newsale">
//                       <p
//                         className="px-2 py-[.1em] mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
//                         onClick={toggleBox}
//                       >
//                         {" "}
//                         Sales
//                       </p>
//                     </Link>
//                     <Link to="/newexpense">
//                       <p
//                         className="px-2 py-[.1em] mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
//                         onClick={toggleBox}
//                       >
//                         {" "}
//                         Expense
//                       </p>
//                     </Link>
//                     <Link to="/newcustomer">
//                       <p
//                         className="px-2 py-[.1em]  mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
//                         onClick={toggleBox}
//                       >
//                         {" "}
//                         Customer
//                       </p>
//                     </Link>
//                     <Link to="/newsupplier">
//                       <p
//                         className="px-2 py-[.1em] bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
//                         onClick={toggleBox}
//                       >
//                         {" "}
//                         Supplier
//                       </p>
//                     </Link>
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div>
//               <p className="text-primary text-sm">{currentDateTime}</p>
//             </div>
//             <div className="flex justify-end items-center">
//               <div className="flex justify-between gap-3 bg-primary py-[.6em] px-4 rounded-tl-2xl rounded-br-2xl rounded">
//                 <div>
//                   <p className=" font-bold flex items-center gap-1 py-2 px-4 bg-white text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer h-10">
//                     POS
//                     <span className="text-xl">
//                       <MdPointOfSale />
//                     </span>
//                   </p>
//                 </div>

//                 <div>
//                   <p className="relative font-bold flex items-center gap-1 py-2 px-4 bg-white text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer h-10">
//                     <div className="absolute -right-2 -top-2 bg-red-500 border border-white text-white w-6 h-6 flex justify-center items-center text-center rounded-full text-xs font-normal">
//                       55
//                     </div>
//                     <span className="text-xl">
//                       {" "}
//                       <MdNotifications />
//                     </span>
//                   </p>
//                 </div>
//                 <div>
//                   <p className="relative font-bold flex items-center gap-1 py-2 px-4 bg-white text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer h-10">
//                     <div className="absolute -right-2 -top-2 bg-red-500 border border-white text-white w-6 h-6 flex justify-center items-center text-center rounded-full text-xs font-normal">
//                       123
//                     </div>
//                     <span className="text-xl">
//                       {" "}
//                       <IoWarning />
//                     </span>
//                   </p>
//                 </div>
//                 <div>
//                   <div className=" font-bold gap-1 py-2 px-4 bg-white text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer capitalize leading-3 h-10">
//                     <p>hossain shovon</p>
//                     <p className="text-sm font-normal">super admin</p>
//                   </div>
//                 </div>
//                 <div>
//                   <p className="font-bold flex items-center gap-1 p-2 pb-0 bg-white text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer h-10">
//                     <span className="text-3xl mb-2">
//                       <FaUserLarge />
//                     </span>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </header>

//           {/* Page Content */}
//           <main className="flex-1 p-3 overflow-y-auto">{children}</main>
//         </div>
//         <footer className="bg-primary text-white text-center text-sm py-2">
//           Copyright &copy; 2024 All rights reserved by MD Infotech <br />
//           Opt2Cash
//         </footer>
//       </div>
//     </div>
//   );
// };

// export default DashboardLayout;

import React, { useState, useEffect, useRef } from "react";
//import { Link } from "react-router-dom";
//import Sidebar from "./Sidebar";
import { FaCirclePlus } from "react-icons/fa6";
import { FaCircleMinus } from "react-icons/fa6";

import { MdPointOfSale } from "react-icons/md";
import { MdNotifications } from "react-icons/md";
import { IoWarning } from "react-icons/io5";
import { FaUserLarge } from "react-icons/fa6";

import { Link, NavLink } from "react-router-dom";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { FaBarsStaggered } from "react-icons/fa6";
import { TbCircleDotFilled } from "react-icons/tb";
import { FaTruck } from "react-icons/fa";
import { BsCapsulePill } from "react-icons/bs";
import { FaCartPlus } from "react-icons/fa6";
import { HiMiniShoppingBag } from "react-icons/hi2";
import { IoArrowUndoSharp } from "react-icons/io5";
import { FaMinus } from "react-icons/fa";
import { IoBarChart } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { MdSms } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";

const DashboardLayout = ({ children }) => {
  const [currentDateTime, setCurrentDateTime] = useState("");
  //const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isBoxOpen, setIsBoxOpen] = useState(false); // State to track the visibility of the absolute box
  const boxRef = useRef(null); // Reference to the div containing the button and absolute box
  const [isOpen, setIsOpen] = useState(false);

  // Toggle sidebar function
  const toggleSidebar = () => setIsOpen(!isOpen);
  useEffect(() => {
    const updateDateTime = () => {
      const date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      const formattedDateTime = date.toLocaleDateString("en-US", options);
      setCurrentDateTime(formattedDateTime);
    };

    updateDateTime(); // Initial call to set date and time immediately

    const intervalId = setInterval(updateDateTime, 1000); // Update every second
    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  // Function to toggle the box and button label
  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  // Close the box if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsBoxOpen(false);
      }
    };

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup on unmount
    };
  }, [boxRef]);

  const [isVisible, setIsVisible] = useState(false);

  // Show or hide button based on scroll position
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  // Attach scroll event to toggle visibility
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="flex min-h-[185vh] max-h-full bg-primary  ">
      {/* Sidebar */}
      {/* <Sidebar className=" w-20  " /> */}
      <aside className="w-[18%] text-white hidden lg:flex  lg:flex-col justify-between">
        <div>
          <Link to="/">
            <div className="logo text-center text-3xl bg-white  px-1 py-3 rounded-lg mx-2 mt-3 font-semibold cursor-pointer">
              <p> Opt2Cash</p>
              {/* <p className="text-xs ">Pharmacy Point Of Sale System</p> */}
            </div>
          </Link>
          <nav className="mt-3">
            <ul>
              {/* Dashboard NavLink */}
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-lg flex items-center gap-2 py-3 px-2 ms-2 text-primary font-semibold bg-white  inverted-border-radius"
                    : " text-lg flex items-center gap-2 py-3 px-2 ms-2 text-white font-semibold hover:text-secondary"
                }
              >
                <span
                  className={`p-1 rounded ${
                    window.location.pathname === "/"
                      ? "bg-primary text-white"
                      : "bg-white text-primary"
                  }`}
                >
                  <RiDashboardHorizontalFill />
                </span>
                Dashboard
              </NavLink>

              {/* Customer NavLink */}
              <div
                className={`${
                  window.location.pathname.includes("/newcustomer") ||
                  window.location.pathname.includes("/customerlist")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/newcustomer"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/customerlist")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/newcustomer") ||
                      window.location.pathname.includes("/customerlist")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <FaUsers />
                  </span>
                  Customer
                </NavLink>

                {/* Customer Sublist */}
                {(window.location.pathname.includes("/newcustomer") ||
                  window.location.pathname.includes("/customerlist")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/newcustomer"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      New Customer
                    </NavLink>
                    <NavLink
                      to="/customerlist"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Customer List
                    </NavLink>
                  </div>
                )}
              </div>
              {/* Supplier Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/newsupplier") ||
                  window.location.pathname.includes("/supplierlist")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/newsupplier"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/supplierlist")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/newsupplier") ||
                      window.location.pathname.includes("/supplierlist")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <FaTruck />
                  </span>
                  Supplier
                </NavLink>

                {/* Supplier Sublist */}
                {(window.location.pathname.includes("/newsupplier") ||
                  window.location.pathname.includes("/supplierlist")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/newsupplier"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      New Supplier
                    </NavLink>
                    <NavLink
                      to="/supplierlist"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Supplier List
                    </NavLink>
                  </div>
                )}
              </div>
              {/* Medicine Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/addmedicine") ||
                  window.location.pathname.includes("/medicinelist") ||
                  window.location.pathname.includes("/addunit") ||
                  window.location.pathname.includes("/unitlist") ||
                  window.location.pathname.includes("/addtype") ||
                  window.location.pathname.includes("/typelist") ||
                  window.location.pathname.includes("/leafsetting")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/addmedicine"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/medicinelist") ||
                      window.location.pathname.includes("/addunit") ||
                      window.location.pathname.includes("/unitlist") ||
                      window.location.pathname.includes("/addtype") ||
                      window.location.pathname.includes("/typelist") ||
                      window.location.pathname.includes("/leafsetting")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/addmedicine") ||
                      window.location.pathname.includes("/medicinelist") ||
                      window.location.pathname.includes("/addunit") ||
                      window.location.pathname.includes("/unitlist") ||
                      window.location.pathname.includes("/addtype") ||
                      window.location.pathname.includes("/typelist") ||
                      window.location.pathname.includes("/leafsetting")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <BsCapsulePill />
                  </span>
                  Medicine
                </NavLink>

                {/* Medicine Sublist */}
                {(window.location.pathname.includes("/addmedicine") ||
                  window.location.pathname.includes("/medicinelist") ||
                  window.location.pathname.includes("/addunit") ||
                  window.location.pathname.includes("/unitlist") ||
                  window.location.pathname.includes("/addtype") ||
                  window.location.pathname.includes("/typelist") ||
                  window.location.pathname.includes("/leafsetting")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/addmedicine"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Add Medicine
                    </NavLink>
                    <NavLink
                      to="/medicinelist"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Medicine List
                    </NavLink>
                    <NavLink
                      to="/addunit"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Add Unit
                    </NavLink>
                    <NavLink
                      to="/unitlist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Unit List
                    </NavLink>
                    <NavLink
                      to="/addtype"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Add Type
                    </NavLink>
                    <NavLink
                      to="/typelist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Type List
                    </NavLink>
                    <NavLink
                      to="/leafsetting"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Leaf Setting
                    </NavLink>
                  </div>
                )}
              </div>
              {/* Purchase Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/addpurchase") ||
                  window.location.pathname.includes("/purchaselist")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/addpurchase"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/purchaselist")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/addpurchase") ||
                      window.location.pathname.includes("/purchaselist")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <FaCartPlus />
                  </span>
                  Purchase
                </NavLink>

                {/*  Purchase Sublist */}
                {(window.location.pathname.includes("/addpurchase") ||
                  window.location.pathname.includes("/purchaselist")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/addpurchase"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Add Purchase
                    </NavLink>
                    <NavLink
                      to="/purchaselist"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Purchase List
                    </NavLink>
                  </div>
                )}
              </div>
              {/* Sale Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/newsale") ||
                  window.location.pathname.includes("/saleslist")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/newsale"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/saleslist")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/newsale") ||
                      window.location.pathname.includes("/saleslist")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <HiMiniShoppingBag />
                  </span>
                  Sale
                </NavLink>

                {/*  Sales Sublist */}
                {(window.location.pathname.includes("/newsale") ||
                  window.location.pathname.includes("/saleslist")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/newsale"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      New Sale
                    </NavLink>
                    <NavLink
                      to="/saleslist"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Sales List
                    </NavLink>
                  </div>
                )}
              </div>
              {/* Return Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/addreturn") ||
                  window.location.pathname.includes("/salesreturnlist") ||
                  window.location.pathname.includes("/purchasereturnlist") ||
                  window.location.pathname.includes("/wastagereturnlist")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/addreturn"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/salesreturnlist") ||
                      window.location.pathname.includes(
                        "/purchasereturnlist"
                      ) ||
                      window.location.pathname.includes("/wastagereturnlist")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/addreturn") ||
                      window.location.pathname.includes("/salesreturnlist") ||
                      window.location.pathname.includes(
                        "/purchasereturnlist"
                      ) ||
                      window.location.pathname.includes("/wastagereturnlist")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <IoArrowUndoSharp />
                  </span>
                  Return
                </NavLink>

                {/* Return Sublist */}
                {(window.location.pathname.includes("/addreturn") ||
                  window.location.pathname.includes("/salesreturnlist") ||
                  window.location.pathname.includes("/purchasereturnlist") ||
                  window.location.pathname.includes("/wastagereturnlist")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/addreturn"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Add Return
                    </NavLink>
                    <NavLink
                      to="/salesreturnlist"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Sales Return List
                    </NavLink>
                    <NavLink
                      to="/purchasereturnlist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Purchase Return List
                    </NavLink>
                    <NavLink
                      to="/wastagereturnlist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Wastage Return List
                    </NavLink>
                  </div>
                )}
              </div>
              {/* Expense Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/newexpense") ||
                  window.location.pathname.includes("/expenselist") ||
                  window.location.pathname.includes("/newcategory") ||
                  window.location.pathname.includes("/categorylist")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/newexpense"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/expenselist") ||
                      window.location.pathname.includes("/newcategory") ||
                      window.location.pathname.includes("/categorylist")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/newexpense") ||
                      window.location.pathname.includes("/expenselist") ||
                      window.location.pathname.includes("/newcategory") ||
                      window.location.pathname.includes("/categorylist")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <FaMinus />
                  </span>
                  Expense
                </NavLink>

                {/* Expense Sublist */}
                {(window.location.pathname.includes("/newexpense") ||
                  window.location.pathname.includes("/expenselist") ||
                  window.location.pathname.includes("/newcategory") ||
                  window.location.pathname.includes("/categorylist")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/newexpense"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      New Expense
                    </NavLink>
                    <NavLink
                      to="/expenselist"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Expense List
                    </NavLink>
                    <NavLink
                      to="/newcategory"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      New Category
                    </NavLink>
                    <NavLink
                      to="/categorylist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Category List
                    </NavLink>
                  </div>
                )}
              </div>
              {/* Report Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/profitlossreport") ||
                  window.location.pathname.includes("/purchasereport") ||
                  window.location.pathname.includes("/purchasereturnreport") ||
                  window.location.pathname.includes("/salesreport") ||
                  window.location.pathname.includes("/salesreturnreport") ||
                  window.location.pathname.includes("/expensereport") ||
                  window.location.pathname.includes("/expireditemsreport") ||
                  window.location.pathname.includes("/stockreport") ||
                  window.location.pathname.includes("/usersalesreport") ||
                  window.location.pathname.includes("/productsalesreport")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/profitlossreport"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/purchasereport") ||
                      window.location.pathname.includes(
                        "/purchasereturnreport"
                      ) ||
                      window.location.pathname.includes("/salesreport") ||
                      window.location.pathname.includes("/salesreturnreport") ||
                      window.location.pathname.includes("/expensereport") ||
                      window.location.pathname.includes(
                        "/expireditemsreport"
                      ) ||
                      window.location.pathname.includes("/stockreport") ||
                      window.location.pathname.includes("/usersalesreport") ||
                      window.location.pathname.includes("/productsalesreport")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/profitlossreport") ||
                      window.location.pathname.includes("/purchasereport") ||
                      window.location.pathname.includes(
                        "/purchasereturnreport"
                      ) ||
                      window.location.pathname.includes("/salesreport") ||
                      window.location.pathname.includes("/salesreturnreport") ||
                      window.location.pathname.includes("/expensereport") ||
                      window.location.pathname.includes(
                        "/expireditemsreport"
                      ) ||
                      window.location.pathname.includes("/stockreport") ||
                      window.location.pathname.includes("/usersalesreport") ||
                      window.location.pathname.includes("/productsalesreport")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <IoBarChart />
                  </span>
                  Report
                </NavLink>

                {/* Report Sublist */}
                {(window.location.pathname.includes("/profitlossreport") ||
                  window.location.pathname.includes("/purchasereport") ||
                  window.location.pathname.includes("/purchasereturnreport") ||
                  window.location.pathname.includes("/salesreport") ||
                  window.location.pathname.includes("/salesreturnreport") ||
                  window.location.pathname.includes("/expensereport") ||
                  window.location.pathname.includes("/expireditemsreport") ||
                  window.location.pathname.includes("/stockreport") ||
                  window.location.pathname.includes("/usersalesreport") ||
                  window.location.pathname.includes("/productsalesreport")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/profitlossreport"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Profit Loss Report
                    </NavLink>
                    <NavLink
                      to="/purchasereport"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Purchase Report
                    </NavLink>
                    <NavLink
                      to="/purchasereturnreport"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Purchase Return Report
                    </NavLink>
                    <NavLink
                      to="/salesreport"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Sales Report
                    </NavLink>
                    <NavLink
                      to="/salesreturnreport"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Sales Return Report
                    </NavLink>
                    <NavLink
                      to="/expensereport"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Expense Report
                    </NavLink>
                    <NavLink
                      to="/expireditemsreport"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Expired Items Report
                    </NavLink>
                    <NavLink
                      to="/stockreport"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Stock Report
                    </NavLink>
                    <NavLink
                      to="/usersalesreport"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      User Wise Sales Report
                    </NavLink>
                    <NavLink
                      to="/productsalesreport"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Product Wise Sales Report
                    </NavLink>
                  </div>
                )}
              </div>
              {/* Users Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/newuser") ||
                  window.location.pathname.includes("/userlist") ||
                  window.location.pathname.includes("/rolelist")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/newuser"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/userlist") ||
                      window.location.pathname.includes("/rolelist")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/newuser") ||
                      window.location.pathname.includes("/userlist") ||
                      window.location.pathname.includes("/rolelist")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <FaUser />
                  </span>
                  Users
                </NavLink>

                {/* Users Sublist */}
                {(window.location.pathname.includes("/newuser") ||
                  window.location.pathname.includes("/userlist") ||
                  window.location.pathname.includes("/rolelist")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/newuser"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      New User
                    </NavLink>
                    <NavLink
                      to="/userlist"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      User List
                    </NavLink>
                    <NavLink
                      to="/rolelist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Role List
                    </NavLink>
                  </div>
                )}
              </div>
              {/* SMS Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/sendsms") ||
                  window.location.pathname.includes("/smstemplate") ||
                  window.location.pathname.includes("/smsapi")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/sendsms"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/smstemplate") ||
                      window.location.pathname.includes("/smsapi")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/sendsms") ||
                      window.location.pathname.includes("/smstemplate") ||
                      window.location.pathname.includes("/smsapi")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <MdSms />
                  </span>
                  SMS
                </NavLink>

                {/* Users Sublist */}
                {(window.location.pathname.includes("/sendsms") ||
                  window.location.pathname.includes("/smstemplate") ||
                  window.location.pathname.includes("/smsapi")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/sendsms"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Send SMS
                    </NavLink>
                    <NavLink
                      to="/smstemplate"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      SMS Template
                    </NavLink>
                    <NavLink
                      to="/smsapi"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      SMS API
                    </NavLink>
                  </div>
                )}
              </div>
              {/* Settings Navlink */}
              <div
                className={`${
                  window.location.pathname.includes("/companyprofile") ||
                  window.location.pathname.includes("/sitesettings") ||
                  window.location.pathname.includes("/taxlist") ||
                  window.location.pathname.includes("/unitslist") ||
                  window.location.pathname.includes("/paymenttypelist") ||
                  window.location.pathname.includes("/currencylist") ||
                  window.location.pathname.includes("/language") ||
                  window.location.pathname.includes("/changepassword") ||
                  window.location.pathname.includes("/databasebackup") ||
                  window.location.pathname.includes("/help")
                    ? "bg-white text-primary  inverted-border-radius ms-2"
                    : "ms-2"
                }`}
              >
                <NavLink
                  to="/companyprofile"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-3 px-2 font-semibold ${
                      isActive ||
                      window.location.pathname.includes("/sitesettings") ||
                      window.location.pathname.includes("/taxlist") ||
                      window.location.pathname.includes("/unitslist") ||
                      window.location.pathname.includes("/paymenttypelist") ||
                      window.location.pathname.includes("/currencylist") ||
                      window.location.pathname.includes("/language") ||
                      window.location.pathname.includes("/changepassword") ||
                      window.location.pathname.includes("/databasebackup") ||
                      window.location.pathname.includes("/help")
                        ? "text-lg bg-white text-primary inverted-border-radius"
                        : "text-lg text-white hover:text-secondary"
                    }`
                  }
                >
                  <span
                    className={`bg-primary p-1 rounded ${
                      window.location.pathname.includes("/companyprofile") ||
                      window.location.pathname.includes("/sitesettings") ||
                      window.location.pathname.includes("/taxlist") ||
                      window.location.pathname.includes("/unitslist") ||
                      window.location.pathname.includes("/paymenttypelist") ||
                      window.location.pathname.includes("/currencylist") ||
                      window.location.pathname.includes("/language") ||
                      window.location.pathname.includes("/changepassword") ||
                      window.location.pathname.includes("/databasebackup") ||
                      window.location.pathname.includes("/help")
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    <IoSettingsSharp />
                  </span>
                  Settings
                </NavLink>

                {/*Settings Sublist */}
                {(window.location.pathname.includes("/companyprofile") ||
                  window.location.pathname.includes("/sitesettings") ||
                  window.location.pathname.includes("/taxlist") ||
                  window.location.pathname.includes("/unitslist") ||
                  window.location.pathname.includes("/paymenttypelist") ||
                  window.location.pathname.includes("/currencylist") ||
                  window.location.pathname.includes("/language") ||
                  window.location.pathname.includes("/changepassword") ||
                  window.location.pathname.includes("/databasebackup") ||
                  window.location.pathname.includes("/help")) && (
                  <div className="flex flex-col ms-8 bg-white rounded-lg ps-2 pb-2">
                    <NavLink
                      to="/companyprofile"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Company Profile
                    </NavLink>
                    <NavLink
                      to="/sitesettings"
                      className={({ isActive }) =>
                        isActive
                          ? " text-primary font-semibold mb-1 flex items-center gap-2"
                          : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Site Settings
                    </NavLink>
                    <NavLink
                      to="/taxlist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Tax List
                    </NavLink>
                    <NavLink
                      to="/unitslist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Units List
                    </NavLink>
                    <NavLink
                      to="/paymenttypelist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Payment Types List
                    </NavLink>
                    <NavLink
                      to="/currencylist"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Currency List
                    </NavLink>
                    <NavLink
                      to="/language"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Language
                    </NavLink>
                    <NavLink
                      to="/changepassword"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Change Password
                    </NavLink>
                    <NavLink
                      to="/databasebackup"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Database Backup
                    </NavLink>
                    <NavLink
                      to="/help"
                      className={({ isActive }) =>
                        isActive
                          ? "text-primary font-semibold mb-1 flex items-center gap-2"
                          : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                      }
                    >
                      <span>
                        <TbCircleDotFilled />
                      </span>
                      Help
                    </NavLink>
                  </div>
                )}
              </div>
            </ul>
          </nav>
        </div>
      </aside>
      <div className="flex-1 flex flex-col  w-[82%] ">
        {/* Main Content */}
        <div className="flex-1 flex flex-col bg-white lg:rounded-3xl md:rounded-2xl rounded-lg  lg:mt-2 lg:mr-1 lg:ms-0 m-2 ">
          {/* Navbar */}
          <header className="lg:flex lg:justify-between items-center m-3 grid md:flex md:justify-between grid-cols-2">
            <div className="lg:hidden ">
              <div
                onClick={toggleSidebar}
                className="text-3xl text-primary cursor-pointer"
              >
                <FaBarsStaggered />
              </div>

              <aside
                className={`fixed top-0 left-0 pb-3 h-full bg-primary transition-transform duration-500 transform ${
                  isOpen ? "translate-x-0" : "-translate-x-full"
                } lg:translate-x-0 lg:w-60 lg:flex lg:flex-col justify-between z-10 text-white w-2/3 overflow-y-auto`}
              >
                <div>
                  <Link to="/">
                    <div className="logo text-center text-3xl bg-white px-1 lg:py-3 py-2 rounded-lg mx-2 mt-3 font-semibold cursor-pointer">
                      <p>Opt2Cash</p>
                    </div>
                  </Link>
                  <nav className="mt-3">
                    <ul>
                      <NavLink
                        to="/"
                        className={({ isActive }) =>
                          isActive
                            ? "text-lg flex items-center gap-2 py-3 px-2 ms-2 text-primary font-semibold bg-white inverted-border-radius"
                            : "text-lg flex items-center gap-2 py-3 px-2 ms-2 text-white font-semibold hover:text-secondary"
                        }
                      >
                        <span
                          className={`p-1 rounded ${
                            window.location.pathname === "/"
                              ? "bg-primary text-white"
                              : "bg-white text-primary"
                          }`}
                        >
                          <RiDashboardHorizontalFill />
                        </span>
                        Dashboard
                      </NavLink>

                      {/* Customer NavLink */}
                      <div
                        className={`${
                          window.location.pathname.includes("/newcustomer") ||
                          window.location.pathname.includes("/customerlist")
                            ? "bg-white text-primary inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/newcustomer"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes("/customerlist")
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes(
                                "/newcustomer"
                              ) ||
                              window.location.pathname.includes("/customerlist")
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <FaUsers />
                          </span>
                          Customer
                        </NavLink>

                        {/* Customer Sublist */}
                        {(window.location.pathname.includes("/newcustomer") ||
                          window.location.pathname.includes(
                            "/customerlist"
                          )) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/newcustomer"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              New Customer
                            </NavLink>
                            <NavLink
                              to="/customerlist"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Customer List
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* Supplier Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes("/newsupplier") ||
                          window.location.pathname.includes("/supplierlist")
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/newsupplier"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes("/supplierlist")
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes(
                                "/newsupplier"
                              ) ||
                              window.location.pathname.includes("/supplierlist")
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <FaTruck />
                          </span>
                          Supplier
                        </NavLink>

                        {/* Supplier Sublist */}
                        {(window.location.pathname.includes("/newsupplier") ||
                          window.location.pathname.includes(
                            "/supplierlist"
                          )) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/newsupplier"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              New Supplier
                            </NavLink>
                            <NavLink
                              to="/supplierlist"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Supplier List
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* Medicine Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes("/addmedicine") ||
                          window.location.pathname.includes("/medicinelist") ||
                          window.location.pathname.includes("/addunit") ||
                          window.location.pathname.includes("/unitlist") ||
                          window.location.pathname.includes("/addtype") ||
                          window.location.pathname.includes("/typelist") ||
                          window.location.pathname.includes("/leafsetting")
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/addmedicine"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes(
                                "/medicinelist"
                              ) ||
                              window.location.pathname.includes("/addunit") ||
                              window.location.pathname.includes("/unitlist") ||
                              window.location.pathname.includes("/addtype") ||
                              window.location.pathname.includes("/typelist") ||
                              window.location.pathname.includes("/leafsetting")
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes(
                                "/addmedicine"
                              ) ||
                              window.location.pathname.includes(
                                "/medicinelist"
                              ) ||
                              window.location.pathname.includes("/addunit") ||
                              window.location.pathname.includes("/unitlist") ||
                              window.location.pathname.includes("/addtype") ||
                              window.location.pathname.includes("/typelist") ||
                              window.location.pathname.includes("/leafsetting")
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <BsCapsulePill />
                          </span>
                          Medicine
                        </NavLink>

                        {/* Medicine Sublist */}
                        {(window.location.pathname.includes("/addmedicine") ||
                          window.location.pathname.includes("/medicinelist") ||
                          window.location.pathname.includes("/addunit") ||
                          window.location.pathname.includes("/unitlist") ||
                          window.location.pathname.includes("/addtype") ||
                          window.location.pathname.includes("/typelist") ||
                          window.location.pathname.includes(
                            "/leafsetting"
                          )) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/addmedicine"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Add Medicine
                            </NavLink>
                            <NavLink
                              to="/medicinelist"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Medicine List
                            </NavLink>
                            <NavLink
                              to="/addunit"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Add Unit
                            </NavLink>
                            <NavLink
                              to="/unitlist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Unit List
                            </NavLink>
                            <NavLink
                              to="/addtype"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Add Type
                            </NavLink>
                            <NavLink
                              to="/typelist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Type List
                            </NavLink>
                            <NavLink
                              to="/leafsetting"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Leaf Setting
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* Purchase Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes("/addpurchase") ||
                          window.location.pathname.includes("/purchaselist")
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/addpurchase"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes("/purchaselist")
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes(
                                "/addpurchase"
                              ) ||
                              window.location.pathname.includes("/purchaselist")
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <FaCartPlus />
                          </span>
                          Purchase
                        </NavLink>

                        {/*  Purchase Sublist */}
                        {(window.location.pathname.includes("/addpurchase") ||
                          window.location.pathname.includes(
                            "/purchaselist"
                          )) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/addpurchase"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Add Purchase
                            </NavLink>
                            <NavLink
                              to="/purchaselist"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Purchase List
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* Sale Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes("/newsale") ||
                          window.location.pathname.includes("/saleslist")
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/newsale"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes("/saleslist")
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes("/newsale") ||
                              window.location.pathname.includes("/saleslist")
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <HiMiniShoppingBag />
                          </span>
                          Sale
                        </NavLink>

                        {/*  Sales Sublist */}
                        {(window.location.pathname.includes("/newsale") ||
                          window.location.pathname.includes("/saleslist")) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/newsale"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              New Sale
                            </NavLink>
                            <NavLink
                              to="/saleslist"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Sales List
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* Return Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes("/addreturn") ||
                          window.location.pathname.includes(
                            "/salesreturnlist"
                          ) ||
                          window.location.pathname.includes(
                            "/purchasereturnlist"
                          ) ||
                          window.location.pathname.includes(
                            "/wastagereturnlist"
                          )
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/addreturn"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes(
                                "/salesreturnlist"
                              ) ||
                              window.location.pathname.includes(
                                "/purchasereturnlist"
                              ) ||
                              window.location.pathname.includes(
                                "/wastagereturnlist"
                              )
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes("/addreturn") ||
                              window.location.pathname.includes(
                                "/salesreturnlist"
                              ) ||
                              window.location.pathname.includes(
                                "/purchasereturnlist"
                              ) ||
                              window.location.pathname.includes(
                                "/wastagereturnlist"
                              )
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <IoArrowUndoSharp />
                          </span>
                          Return
                        </NavLink>

                        {/* Return Sublist */}
                        {(window.location.pathname.includes("/addreturn") ||
                          window.location.pathname.includes(
                            "/salesreturnlist"
                          ) ||
                          window.location.pathname.includes(
                            "/purchasereturnlist"
                          ) ||
                          window.location.pathname.includes(
                            "/wastagereturnlist"
                          )) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/addreturn"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Add Return
                            </NavLink>
                            <NavLink
                              to="/salesreturnlist"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Sales Return List
                            </NavLink>
                            <NavLink
                              to="/purchasereturnlist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Purchase Return List
                            </NavLink>
                            <NavLink
                              to="/wastagereturnlist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Wastage Return List
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* Expense Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes("/newexpense") ||
                          window.location.pathname.includes("/expenselist") ||
                          window.location.pathname.includes("/newcategory") ||
                          window.location.pathname.includes("/categorylist")
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/newexpense"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes(
                                "/expenselist"
                              ) ||
                              window.location.pathname.includes(
                                "/newcategory"
                              ) ||
                              window.location.pathname.includes("/categorylist")
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes(
                                "/newexpense"
                              ) ||
                              window.location.pathname.includes(
                                "/expenselist"
                              ) ||
                              window.location.pathname.includes(
                                "/newcategory"
                              ) ||
                              window.location.pathname.includes("/categorylist")
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <FaMinus />
                          </span>
                          Expense
                        </NavLink>

                        {/* Expense Sublist */}
                        {(window.location.pathname.includes("/newexpense") ||
                          window.location.pathname.includes("/expenselist") ||
                          window.location.pathname.includes("/newcategory") ||
                          window.location.pathname.includes(
                            "/categorylist"
                          )) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/newexpense"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              New Expense
                            </NavLink>
                            <NavLink
                              to="/expenselist"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Expense List
                            </NavLink>
                            <NavLink
                              to="/newcategory"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              New Category
                            </NavLink>
                            <NavLink
                              to="/categorylist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Category List
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* Report Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes(
                            "/profitlossreport"
                          ) ||
                          window.location.pathname.includes(
                            "/purchasereport"
                          ) ||
                          window.location.pathname.includes(
                            "/purchasereturnreport"
                          ) ||
                          window.location.pathname.includes("/salesreport") ||
                          window.location.pathname.includes(
                            "/salesreturnreport"
                          ) ||
                          window.location.pathname.includes("/expensereport") ||
                          window.location.pathname.includes(
                            "/expireditemsreport"
                          ) ||
                          window.location.pathname.includes("/stockreport") ||
                          window.location.pathname.includes(
                            "/usersalesreport"
                          ) ||
                          window.location.pathname.includes(
                            "/productsalesreport"
                          )
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/profitlossreport"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes(
                                "/purchasereport"
                              ) ||
                              window.location.pathname.includes(
                                "/purchasereturnreport"
                              ) ||
                              window.location.pathname.includes(
                                "/salesreport"
                              ) ||
                              window.location.pathname.includes(
                                "/salesreturnreport"
                              ) ||
                              window.location.pathname.includes(
                                "/expensereport"
                              ) ||
                              window.location.pathname.includes(
                                "/expireditemsreport"
                              ) ||
                              window.location.pathname.includes(
                                "/stockreport"
                              ) ||
                              window.location.pathname.includes(
                                "/usersalesreport"
                              ) ||
                              window.location.pathname.includes(
                                "/productsalesreport"
                              )
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes(
                                "/profitlossreport"
                              ) ||
                              window.location.pathname.includes(
                                "/purchasereport"
                              ) ||
                              window.location.pathname.includes(
                                "/purchasereturnreport"
                              ) ||
                              window.location.pathname.includes(
                                "/salesreport"
                              ) ||
                              window.location.pathname.includes(
                                "/salesreturnreport"
                              ) ||
                              window.location.pathname.includes(
                                "/expensereport"
                              ) ||
                              window.location.pathname.includes(
                                "/expireditemsreport"
                              ) ||
                              window.location.pathname.includes(
                                "/stockreport"
                              ) ||
                              window.location.pathname.includes(
                                "/usersalesreport"
                              ) ||
                              window.location.pathname.includes(
                                "/productsalesreport"
                              )
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <IoBarChart />
                          </span>
                          Report
                        </NavLink>

                        {/* Report Sublist */}
                        {(window.location.pathname.includes(
                          "/profitlossreport"
                        ) ||
                          window.location.pathname.includes(
                            "/purchasereport"
                          ) ||
                          window.location.pathname.includes(
                            "/purchasereturnreport"
                          ) ||
                          window.location.pathname.includes("/salesreport") ||
                          window.location.pathname.includes(
                            "/salesreturnreport"
                          ) ||
                          window.location.pathname.includes("/expensereport") ||
                          window.location.pathname.includes(
                            "/expireditemsreport"
                          ) ||
                          window.location.pathname.includes("/stockreport") ||
                          window.location.pathname.includes(
                            "/usersalesreport"
                          ) ||
                          window.location.pathname.includes(
                            "/productsalesreport"
                          )) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/profitlossreport"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Profit Loss Report
                            </NavLink>
                            <NavLink
                              to="/purchasereport"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Purchase Report
                            </NavLink>
                            <NavLink
                              to="/purchasereturnreport"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Purchase Return Report
                            </NavLink>
                            <NavLink
                              to="/salesreport"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Sales Report
                            </NavLink>
                            <NavLink
                              to="/salesreturnreport"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Sales Return Report
                            </NavLink>
                            <NavLink
                              to="/expensereport"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Expense Report
                            </NavLink>
                            <NavLink
                              to="/expireditemsreport"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Expired Items Report
                            </NavLink>
                            <NavLink
                              to="/stockreport"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Stock Report
                            </NavLink>
                            <NavLink
                              to="/usersalesreport"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              User Wise Sales Report
                            </NavLink>
                            <NavLink
                              to="/productsalesreport"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Product Wise Sales Report
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* Users Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes("/newuser") ||
                          window.location.pathname.includes("/userlist") ||
                          window.location.pathname.includes("/rolelist")
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/newuser"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes("/userlist") ||
                              window.location.pathname.includes("/rolelist")
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes("/newuser") ||
                              window.location.pathname.includes("/userlist") ||
                              window.location.pathname.includes("/rolelist")
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <FaUser />
                          </span>
                          Users
                        </NavLink>

                        {/* Users Sublist */}
                        {(window.location.pathname.includes("/newuser") ||
                          window.location.pathname.includes("/userlist") ||
                          window.location.pathname.includes("/rolelist")) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/newuser"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              New User
                            </NavLink>
                            <NavLink
                              to="/userlist"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              User List
                            </NavLink>
                            <NavLink
                              to="/rolelist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Role List
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* SMS Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes("/sendsms") ||
                          window.location.pathname.includes("/smstemplate") ||
                          window.location.pathname.includes("/smsapi")
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/sendsms"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes(
                                "/smstemplate"
                              ) ||
                              window.location.pathname.includes("/smsapi")
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes("/sendsms") ||
                              window.location.pathname.includes(
                                "/smstemplate"
                              ) ||
                              window.location.pathname.includes("/smsapi")
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <MdSms />
                          </span>
                          SMS
                        </NavLink>

                        {/* Users Sublist */}
                        {(window.location.pathname.includes("/sendsms") ||
                          window.location.pathname.includes("/smstemplate") ||
                          window.location.pathname.includes("/smsapi")) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/sendsms"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Send SMS
                            </NavLink>
                            <NavLink
                              to="/smstemplate"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              SMS Template
                            </NavLink>
                            <NavLink
                              to="/smsapi"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              SMS API
                            </NavLink>
                          </div>
                        )}
                      </div>
                      {/* Settings Navlink */}
                      <div
                        className={`${
                          window.location.pathname.includes(
                            "/companyprofile"
                          ) ||
                          window.location.pathname.includes("/sitesettings") ||
                          window.location.pathname.includes("/taxlist") ||
                          window.location.pathname.includes("/unitslist") ||
                          window.location.pathname.includes(
                            "/paymenttypelist"
                          ) ||
                          window.location.pathname.includes("/currencylist") ||
                          window.location.pathname.includes("/language") ||
                          window.location.pathname.includes(
                            "/changepassword"
                          ) ||
                          window.location.pathname.includes(
                            "/databasebackup"
                          ) ||
                          window.location.pathname.includes("/help")
                            ? "bg-white text-primary  inverted-border-radius ms-2"
                            : "ms-2"
                        }`}
                      >
                        <NavLink
                          to="/companyprofile"
                          className={({ isActive }) =>
                            `flex items-center gap-2 py-3 px-2 font-semibold ${
                              isActive ||
                              window.location.pathname.includes(
                                "/sitesettings"
                              ) ||
                              window.location.pathname.includes("/taxlist") ||
                              window.location.pathname.includes("/unitslist") ||
                              window.location.pathname.includes(
                                "/paymenttypelist"
                              ) ||
                              window.location.pathname.includes(
                                "/currencylist"
                              ) ||
                              window.location.pathname.includes("/language") ||
                              window.location.pathname.includes(
                                "/changepassword"
                              ) ||
                              window.location.pathname.includes(
                                "/databasebackup"
                              ) ||
                              window.location.pathname.includes("/help")
                                ? "text-lg bg-white text-primary inverted-border-radius"
                                : "text-lg text-white hover:text-secondary"
                            }`
                          }
                        >
                          <span
                            className={`bg-primary p-1 rounded ${
                              window.location.pathname.includes(
                                "/companyprofile"
                              ) ||
                              window.location.pathname.includes(
                                "/sitesettings"
                              ) ||
                              window.location.pathname.includes("/taxlist") ||
                              window.location.pathname.includes("/unitslist") ||
                              window.location.pathname.includes(
                                "/paymenttypelist"
                              ) ||
                              window.location.pathname.includes(
                                "/currencylist"
                              ) ||
                              window.location.pathname.includes("/language") ||
                              window.location.pathname.includes(
                                "/changepassword"
                              ) ||
                              window.location.pathname.includes(
                                "/databasebackup"
                              ) ||
                              window.location.pathname.includes("/help")
                                ? "bg-primary text-white"
                                : "bg-white text-primary"
                            }`}
                          >
                            <IoSettingsSharp />
                          </span>
                          Settings
                        </NavLink>

                        {/*Settings Sublist */}
                        {(window.location.pathname.includes(
                          "/companyprofile"
                        ) ||
                          window.location.pathname.includes("/sitesettings") ||
                          window.location.pathname.includes("/taxlist") ||
                          window.location.pathname.includes("/unitslist") ||
                          window.location.pathname.includes(
                            "/paymenttypelist"
                          ) ||
                          window.location.pathname.includes("/currencylist") ||
                          window.location.pathname.includes("/language") ||
                          window.location.pathname.includes(
                            "/changepassword"
                          ) ||
                          window.location.pathname.includes(
                            "/databasebackup"
                          ) ||
                          window.location.pathname.includes("/help")) && (
                          <div className="flex flex-col mx-3 bg-white rounded-lg ps-2 pb-2">
                            <NavLink
                              to="/companyprofile"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Company Profile
                            </NavLink>
                            <NavLink
                              to="/sitesettings"
                              className={({ isActive }) =>
                                isActive
                                  ? " text-primary font-semibold mb-1 flex items-center gap-2"
                                  : " text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Site Settings
                            </NavLink>
                            <NavLink
                              to="/taxlist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Tax List
                            </NavLink>
                            <NavLink
                              to="/unitslist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Units List
                            </NavLink>
                            <NavLink
                              to="/paymenttypelist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Payment Types List
                            </NavLink>
                            <NavLink
                              to="/currencylist"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Currency List
                            </NavLink>
                            <NavLink
                              to="/language"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Language
                            </NavLink>
                            <NavLink
                              to="/changepassword"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Change Password
                            </NavLink>
                            <NavLink
                              to="/databasebackup"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Database Backup
                            </NavLink>
                            <NavLink
                              to="/help"
                              className={({ isActive }) =>
                                isActive
                                  ? "text-primary font-semibold mb-1 flex items-center gap-2"
                                  : "text-secondary hover:font-semibold hover:text-primary mb-1 flex items-center gap-2"
                              }
                            >
                              <span>
                                <TbCircleDotFilled />
                              </span>
                              Help
                            </NavLink>
                          </div>
                        )}
                      </div>
                    </ul>
                  </nav>
                </div>
              </aside>
            </div>
            <div className="lg:flex justify-between items-center md:flex hidden">
              <div className="relative" ref={boxRef}>
                {/* Button that toggles between 'Add' and 'Close' */}
                <p
                  className="flex items-center gap-1 lg:py-2 py-1 lg:px-4 px-3 bg-primary text-white rounded-tl-2xl rounded-br-2xl rounded cursor-pointer "
                  onClick={toggleBox}
                >
                  Add New
                  <span>
                    {isBoxOpen ? <FaCircleMinus /> : <FaCirclePlus />}
                  </span>
                </p>

                {/* Absolute box, visible only when `isBoxOpen` is true */}
                {isBoxOpen && (
                  <div className="absolute w-auto lg:py-2 py-1 lg:px-4 px-3 text-center font-semibold bg-primary text-white rounded-tl-2xl rounded-br-2xl rounded z-10">
                    <Link to="/addmedicine">
                      <p
                        className="px-2 py-[.1em] mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
                        onClick={toggleBox}
                      >
                        {" "}
                        Medicine
                      </p>
                    </Link>
                    <Link to="/addpurchase">
                      {" "}
                      <p
                        className="px-2 py-[.1em] mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
                        onClick={toggleBox}
                      >
                        {" "}
                        Purchase
                      </p>
                    </Link>
                    <Link to="/newsale">
                      <p
                        className="px-2 py-[.1em] mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
                        onClick={toggleBox}
                      >
                        {" "}
                        Sales
                      </p>
                    </Link>
                    <Link to="/newexpense">
                      <p
                        className="px-2 py-[.1em] mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
                        onClick={toggleBox}
                      >
                        {" "}
                        Expense
                      </p>
                    </Link>
                    <Link to="/newcustomer">
                      <p
                        className="px-2 py-[.1em]  mb-1 bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
                        onClick={toggleBox}
                      >
                        {" "}
                        Customer
                      </p>
                    </Link>
                    <Link to="/newsupplier">
                      <p
                        className="px-2 py-[.1em] bg-white text-primary hover:text-secondary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer"
                        onClick={toggleBox}
                      >
                        {" "}
                        Supplier
                      </p>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="lg:block  hidden">
              <p className="text-primary text-sm">{currentDateTime}</p>
            </div>
            <div className="flex justify-end items-center">
              <div className="flex justify-between lg:gap-3 gap-2 lg:bg-primary lg:py-[.6em] lg:px-4 p-1 lg:rounded-tl-2xl lg:rounded-br-2xl lg:rounded">
                <div>
                  <p className=" font-bold flex items-center gap-1 lg:py-2 lg:px-4 px-2 py-1 lg:bg-white bg-gray-200 text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer h-10">
                    POS
                    <span className="text-xl">
                      <MdPointOfSale />
                    </span>
                  </p>
                </div>

                <div>
                  <p className="relative font-bold flex items-center gap-1 lg:py-2 lg:px-4 py-1 px-3 lg:bg-white bg-gray-200 text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer h-10">
                    <div className="absolute -right-2 -top-2 bg-red-500 border border-white text-white w-6 h-6 flex justify-center items-center text-center rounded-full text-xs font-normal">
                      55
                    </div>
                    <span className="text-xl">
                      {" "}
                      <MdNotifications />
                    </span>
                  </p>
                </div>
                <div>
                  <p className="relative font-bold flex items-center gap-1 lg:py-2 lg:px-4 py-1 px-3  lg:bg-white bg-gray-200 text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer h-10">
                    <div className="absolute -right-2 -top-2 bg-red-500 border border-white text-white w-6 h-6 flex justify-center items-center text-center rounded-full text-xs font-normal">
                      123
                    </div>
                    <span className="text-xl">
                      {" "}
                      <IoWarning />
                    </span>
                  </p>
                </div>
                <div className="lg:block md:flex hidden">
                  <div className=" font-bold gap-1 lg:py-2 lg:px-4 px-3 py-1 lg:bg-white bg-gray-200 text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer capitalize leading-3 h-10">
                    <p>hossain shovon</p>
                    <p className="text-sm font-normal">super admin</p>
                  </div>
                </div>
                <div className="">
                  <p className="font-bold flex items-center gap-1 p-2 pb-0 lg:bg-white bg-gray-200 text-primary rounded-tl-2xl rounded-br-2xl rounded cursor-pointer h-10">
                    <span className="lg:text-3xl text-lg px-1 lg:px-0 mb-2">
                      <FaUserLarge />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </header>

          {/* Page Content */}
          <main className="flex-1 p-3 overflow-y-auto">{children}</main>
        </div>
        <footer className="bg-primary text-white text-center text-sm py-2">
          Copyright &copy; 2024 All rights reserved by MD Infotech <br />
          Opt2Cash
        </footer>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
      ,
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 bg-secondary text-white h-10 w-10 rounded shadow-lg z-100 transition-all duration-500 ease-in-out transform"
          title="Scroll to Top"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default DashboardLayout;

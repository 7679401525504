import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const PurchaseReturnReport = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>PurchaseReturnReport</div>
    </DashboardLayout>
  );
};

export default PurchaseReturnReport;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const SalesReport = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>SalesReport</div>
    </DashboardLayout>
  );
};

export default SalesReport;

import React, { useEffect, useRef, useState } from "react";
import { FaBars } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import Select from "react-select";
import Swal from "sweetalert2";
import { FaUserPlus } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { IoCloseCircleSharp } from "react-icons/io5";
import {
  fetchSuppliers,
  addSupplier,
  fetchMedicinesBySupplier,
} from "../../api/api";
import DatePicker from "react-datepicker"; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import CSS for DatePicker
import { getYear, getMonth } from "date-fns"; // Import getYear and getMonth from date-fns

// Helper function for generating year range
const generateYearRange = (start, end, step) => {
  let range = [];
  for (let i = start; i < end; i += step) {
    range.push(i);
  }
  return range;
};

const AddPurchase = () => {
  useEffect(() => {
    document.title = "Add Purchase";
  }, []);

  // DatePicker and range setup

  const [formData, setFormData] = useState({
    id: "",
    supplier_name: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [startDate, setStartDate] = useState(new Date());
  const years = generateYearRange(2020, 2041, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Rows state initialization for purchase details
  const [rows, setRows] = useState([
    {
      selectedMedicine: "",
      batchNo: "",
      expiryDate: new Date(),
      stockQty: 233,
      boxPattern: "",
      boxQty: "",
      quantity: 233,
      supplierPrice: "",
      boxMRP: "",
      totalPurchasePrice: 233,
    },
  ]);

  // Supplier selection logic
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  useEffect(() => {
    const loadSupplierOptions = async () => {
      try {
        const response = await fetchSuppliers();
        const options = response
          .filter((supplier) => supplier.status === 1)
          .map((supplier) => ({
            value: supplier.id,
            label: `${supplier.name} (${supplier.mobile})`,
          }));
        setSupplierOptions(options);
      } catch (error) {
        console.error("Error fetching supplier data:", error);
      }
    };
    loadSupplierOptions();
  }, []);

  // Medicine search functionality
  const [searchTerm, setSearchTerm] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState("");
  const [filteredMedicines, setFilteredMedicines] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupIndex, setPopupIndex] = useState(null);
  const popupRef = useRef(null);

  useEffect(() => {
    if (selectedSupplier) {
      const loadMedicines = async () => {
        try {
          const response = await fetchMedicinesBySupplier(
            selectedSupplier.value
          );
          setMedicines(response);
          setFilteredMedicines(response);
        } catch (error) {
          console.error("Error fetching medicines:", error);
        }
      };
      loadMedicines();
    }
  }, [selectedSupplier]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = medicines.filter((medicine) =>
      medicine.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredMedicines(filtered);
  };

  // Handle medicine selection in the popup
  const handleSelectMedicine = (medicine, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedMedicine = medicine.name;
    setRows(updatedRows);
    closePopup();
  };

  // Open and close popup functionality
  const openPopup = (index) => {
    setPopupIndex(index);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupIndex(null);
  };

  // Close popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Add a new row to the purchase table
  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        selectedMedicine: "",
        batchNo: "",
        expiryDate: new Date(),
        stockQty: 233,
        boxPattern: "",
        boxQty: "",
        quantity: 233,
        supplierPrice: "",
        boxMRP: "",
        totalPurchasePrice: 233,
      },
    ]);
  };

  // Delete a row from the purchase table
  const handleDeleteRow = (index) => {
    if (rows.length <= 1) {
      Swal.fire({
        icon: "warning",
        title: "Cannot delete",
        text: "There is only one row; you can't delete it.",
        confirmButtonText: "OK",
      });
    } else {
      const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
      setRows(updatedRows);
    }
  };

  // Handle the date change in the rows
  const handleDateChange = (index, date) => {
    const updatedRows = rows.map((row, rowIndex) =>
      rowIndex === index ? { ...row, expiryDate: date } : row
    );
    setRows(updatedRows);
  };

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="mb-5 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-primary ">Add Purchase</h2>
          <div>
            <Link to="/purchaselist">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <FaBars />
                </span>
                Purchase List
              </p>
            </Link>
          </div>
        </div>
        <form className="grid grid-cols-2 gap-5 ">
          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="supplier" class="text-primary font-semibold mb-1">
                Supplier*
              </label>
              <div>
                <Select
                  id="supplier_name"
                  name="supplier_name"
                  options={supplierOptions}
                  value={selectedSupplier}
                  onChange={(option) => {
                    setSelectedSupplier(option); // Update selected option
                    if (option) {
                      setFormData((prevData) => ({
                        ...prevData,
                        supplier_name: option.value, // Set supplier_name value
                        supplier_name: option.label, // Set supplier_name value
                      }));
                    }
                  }}
                  placeholder="Select Supplier"
                  isSearchable
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderRadius: "8px",
                      boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      color: "#0090FF",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "#A0AEC0",
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                      color: state.isFocused ? "#000" : "#333",
                      cursor: "pointer",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: "#333",
                    }),
                  }}
                />
              </div>
            </div>
            <div
              className="bg-secondary px-6 py-1.5 rounded-md cursor-pointer"
              // onClick={() => setIsSupplierModalOpen(true)}
            >
              <span className="text-2xl text-white">
                <FaUserPlus />
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Date*</label>
            <DatePicker
              className="text-primary relative px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none w-full"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="flex justify-self-center items-center gap-3 py-3">
                  <button
                    type="button"
                    className="bg-secondary p-1 rounded text-white hover:bg-primary font-semibold"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <FaArrowLeft />
                  </button>
                  <select
                    className="p-1 border  focus:outline-none"
                    value={getMonth(date)}
                    onChange={({ target: { value } }) => changeMonth(value)}
                  >
                    {months.map((month, index) => (
                      <option key={index} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>

                  <select
                    className="p-1 border focus:outline-none"
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>

                  <button
                    type="button"
                    className="bg-secondary p-1 rounded text-white hover:bg-primary font-semibold"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <FaArrowRight />
                  </button>
                </div>
              )}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Invoice No
            </label>
            <input
              type="text"
              name="tax_b"
              value={15}
              className="px-2 py-1.5 border text-gray-500 border-gray-300 bg-gray-300 rounded-lg  focus:outline-none "
              readOnly
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Details</label>
            <input
              type="text"
              name="details"
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
            />
          </div>

          <div class="flex ">
            <div className="flex flex-col w-full">
              <label for="type" class="text-primary font-semibold mb-1">
                Payment Type*
              </label>
              <Select
                id="payment_type"
                name="payment_type"
                placeholder="Select Payment Type"
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderRadius: "8px",
                    boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#0090FF",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A0AEC0",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                    color: state.isFocused ? "#000" : "#333",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333",
                  }),
                }}
              />
            </div>
          </div>
        </form>

        <table className=" mt-10 min-w-full ">
          <thead>
            <tr className=" bg-primary text-center text-white font-semibold text-sm">
              <th className="px-1 py-2 border-r">Medicine Name</th>
              <th className="px-1 py-2 border-r">Batch No</th>
              <th className="px-1 py-2 border-r">Expiry Date</th>
              <th className="px-1 py-2 border-r">Stock Qty</th>
              <th className="px-1 py-2 border-r">Box Pattern</th>
              <th className="px-1 py-2 border-r">Box Qty</th>
              <th className="px-1 py-2 border-r">Quantity</th>
              <th className="px-1 py-2 border-r">Supplier Price</th>
              <th className="px-1 py-2 border-r">Box MRP</th>
              <th className="px-1 py-2 border-r">Total Purchase Price</th>
              <th className="px-1 py-2">Action</th>
            </tr>
          </thead>

          <tbody className="">
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex} className="bg-white text-center text-sm">
                <td className="py-2 px-0.5 relative">
                  <div
                    onClick={() => openPopup(rowIndex)} // Pass rowIndex to openPopup
                    className="border border-primary text-primary rounded px-1 py-1 truncate w-24 cursor-pointer"
                  >
                    {row.selectedMedicine || "Enter Here"}
                  </div>

                  {/* Popup */}
                  {isPopupOpen && popupIndex === rowIndex && (
                    <div className="absolute left-0 mt-2 z-10" ref={popupRef}>
                      <div className="bg-white custom-shadow rounded-lg  w-64  p-3">
                        <div className="flex justify-between items-center gap-2 mb-2">
                          <input
                            type="text"
                            placeholder="Search Medicine"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-full px-2 py-1.5 border border-gray-300 rounded focus:ring-1 focus:ring-secondary focus:outline-none"
                          />
                          <button
                            onClick={closePopup}
                            className="text-primary hover:text-red-500 text-2xl"
                          >
                            <span>
                              <IoCloseCircleSharp />
                            </span>
                          </button>
                        </div>

                        {/* Medicine list */}
                        <ul className="space-y-2 max-h-40 overflow-y-auto">
                          {filteredMedicines
                            .filter(
                              (medicine) =>
                                medicine.supplierId === row.supplierId
                            ) // Filter medicines by supplierId
                            .map((medicine, medicineIndex) => (
                              <li
                                key={medicineIndex}
                                onClick={() =>
                                  handleSelectMedicine(medicine, rowIndex)
                                } // Pass rowIndex when selecting medicine
                                className="px-2 py-1.5 rounded cursor-pointer hover:bg-secondary hover:text-white bg-gray-300 text-primary"
                              >
                                {medicine.name}
                              </li>
                            ))}
                          {filteredMedicines.filter(
                            (medicine) => medicine.supplierId === row.supplierId
                          ).length === 0 && (
                            <li className="px-2 py-1.5 bg-gray-300 text-primary rounded">
                              No results found
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="text"
                    placeholder="Enter Here"
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    value={row.batchNo}
                    onChange={(e) => {
                      const updatedRows = [...rows];
                      updatedRows[rowIndex].batchNo = e.target.value; // Use rowIndex here
                      setRows(updatedRows);
                    }}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <DatePicker
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    selected={row.expiryDate}
                    onChange={(date) => handleDateChange(rowIndex, date)} // Pass rowIndex
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div className="flex justify-self-center items-center gap-3 py-3">
                        <button
                          type="button"
                          className="bg-secondary p-1 rounded text-white hover:bg-primary font-semibold"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          <FaArrowLeft />
                        </button>
                        <select
                          className="p-1 border focus:outline-none"
                          value={getMonth(date)}
                          onChange={({ target: { value } }) =>
                            changeMonth(value)
                          }
                        >
                          {months.map((month, monthIndex) => (
                            <option key={monthIndex} value={monthIndex}>
                              {month}
                            </option>
                          ))}
                        </select>

                        <select
                          className="p-1 border focus:outline-none"
                          value={getYear(date)}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>

                        <button
                          type="button"
                          className="bg-secondary p-1 rounded text-white hover:bg-primary font-semibold"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          <FaArrowRight />
                        </button>
                      </div>
                    )}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    readOnly
                    className="text-gray-500 bg-gray-300 border rounded w-full px-1 py-1 focus:outline-none"
                    value={row.stockQty}
                    onChange={(e) => {
                      const updatedRows = [...rows];
                      updatedRows[rowIndex].stockQty = e.target.value; // Use rowIndex here
                      setRows(updatedRows);
                    }}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    readOnly
                    className="text-gray-500 bg-gray-300 border rounded w-full px-1 py-1 focus:outline-none"
                    value={row.boxPattern}
                    onChange={(e) => {
                      const updatedRows = [...rows];
                      updatedRows[rowIndex].boxPattern = e.target.value; // Use rowIndex here
                      setRows(updatedRows);
                    }}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    value={row.boxQty}
                    onChange={(e) => {
                      const updatedRows = [...rows];
                      updatedRows[rowIndex].boxQty = e.target.value; // Use rowIndex here
                      setRows(updatedRows);
                    }}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    readOnly
                    className="text-gray-500 bg-gray-300 border rounded w-full px-1 py-1 focus:outline-none"
                    value={row.quantity}
                    onChange={(e) => {
                      const updatedRows = [...rows];
                      updatedRows[rowIndex].quantity = e.target.value; // Use rowIndex here
                      setRows(updatedRows);
                    }}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    value={row.supplierPrice}
                    onChange={(e) => {
                      const updatedRows = [...rows];
                      updatedRows[rowIndex].supplierPrice = e.target.value; // Use rowIndex here
                      setRows(updatedRows);
                    }}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    value={row.boxMRP}
                    onChange={(e) => {
                      const updatedRows = [...rows];
                      updatedRows[rowIndex].boxMRP = e.target.value; // Use rowIndex here
                      setRows(updatedRows);
                    }}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    readOnly
                    className="text-gray-500 bg-gray-300 border rounded w-full px-1 py-1 focus:outline-none"
                    value={row.totalPurchasePrice}
                    onChange={(e) => {
                      const updatedRows = [...rows];
                      updatedRows[rowIndex].totalPurchasePrice = e.target.value; // Use rowIndex here
                      setRows(updatedRows);
                    }}
                  />
                </td>

                <td className="py-2 px-0.5">
                  {rowIndex < rows.length - 1 && (
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-600 text-white text-lg px-5 py-1.5 rounded"
                      onClick={() => handleDeleteRow(rowIndex)}
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  )}
                  {/* Always show the "Add" button for the last row */}
                  {rowIndex === rows.length - 1 && (
                    <button
                      type="button"
                      className="bg-primary hover:bg-secondary text-white text-lg px-5 py-1.5 rounded"
                      onClick={handleAddRow}
                    >
                      <FaCirclePlus />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end w-full mt-2">
          <div className="custom-shadow custom-border p-4 px-8 text-center ">
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Sub Total :</p>
              <input
                type="
              text"
                value={212}
                readOnly
                className="border border-gray-300 text-primary rounded  px-1 py-1  bg-gray-300 focus:outline-none  text-end"
              />
            </div>
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Vat :</p>
              <input
                type="
              text"
                value={212}
                readOnly
                className="border border-primary text-primary rounded  px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none text-end"
              />
            </div>
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Discount :</p>
              <input
                type="
              text"
                value={212}
                readOnly
                className="border border-primary text-primary rounded  px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none text-end"
              />
            </div>
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Grand Total :</p>
              <input
                type="
              text"
                value={22}
                readOnly
                className="border border-gray-300 text-primary rounded  px-1 py-1  bg-gray-300 focus:outline-none  text-end"
              />
            </div>
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Paid Amount :</p>
              <input
                type="
              text"
                value={212}
                readOnly
                className="border border-primary text-primary rounded  px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none text-end"
              />
            </div>
            <div className="flex items-center justify-between w-full gap-2">
              <p className="text-primary font-semibold">Due Amount :</p>
              <input
                type="
              text"
                value={62}
                readOnly
                className="border border-gray-300 text-primary rounded  px-1 py-1  bg-gray-300 focus:outline-none  text-end"
              />
            </div>
            <div className="flex justify-between gap-3 mt-4">
              <div className="bg-secondary hover:text-primary font-semibold  py-2 rounded text-white w-full cursor-pointer">
                Full Paid
              </div>
              <div className="bg-primary hover:text-secondary font-semibold py-2 rounded text-white w-full cursor-pointer">
                Save
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddPurchase;

// import React, { useState, useEffect } from "react";
// import { fetchSuppliers, fetchMedicinesBySupplier } from "../../api/api";

// const AddPurchase = () => {
//   const [suppliers, setSuppliers] = useState([]);
//   const [medicines, setMedicines] = useState([]);
//   const [selectedSupplier, setSelectedSupplier] = useState(null);

//   useEffect(() => {
//     const loadSuppliers = async () => {
//       const supplierList = await fetchSuppliers();
//       setSuppliers(supplierList);
//     };
//     loadSuppliers();
//   }, []);

//   const handleSupplierChange = async (e) => {
//     const supplierId = e.target.value;
//     try {
//       const medicines = await fetchMedicinesBySupplier(supplierId);
//       setMedicines(medicines); // Assuming you have a state variable `medicines`
//     } catch (error) {
//       console.error("Error loading medicines:", error);
//     }
//   };

//   return (
//     <div>
//       <h3>Purchase</h3>
//       <label>Supplier</label>
//       <select onChange={handleSupplierChange}>
//         <option value="">Select Supplier</option>
//         {suppliers.map((supplier) => (
//           <option key={supplier.id} value={supplier.id}>
//             {supplier.name}
//           </option>
//         ))}
//       </select>

//       <label>Medicine</label>
//       <select>
//         <option value="">Select Medicine</option>
//         {medicines.map((medicine) => (
//           <option key={medicine.id} value={medicine.id}>
//             {medicine.name}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// export default AddPurchase;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const AddReturn = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>AddReturn</div>
    </DashboardLayout>
  );
};

export default AddReturn;

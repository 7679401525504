import React from 'react';

export const Button = ({ label, onClick, className }) => (
  <button
    className={`bg-blue-500 text-white py-2 px-4 rounded ${className}`}
    onClick={onClick}
  >
    {label}
  </button>
);

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const ExpenseReport = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>ExpenseReport</div>
    </DashboardLayout>
  );
};

export default ExpenseReport;

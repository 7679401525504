import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { FaBars } from "react-icons/fa6";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  editMedicine,
  fetchLeafs,
  fetchUnits,
  fetchTypes,
  fetchSuppliers,
} from "../../api/api";
import Swal from "sweetalert2";

const EditMedicine = () => {
  useEffect(() => {
    document.title = "Edit Medicine";
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const { medicine } = location.state || {};

  const [formData, setFormData] = useState({
    name: "",
    strength: "",
    generic_name: "",
    box_size: "",
    unit_name: "",
    shelf: "",
    details: "",
    medicine_type: "",
    price: "",
    supplier_id: "",
    supplier_price: "",
    tax_a: "",
    tax_b: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [leafOptions, setLeafOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedLeaf, setSelectedLeaf] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [fileError, setFileError] = useState("");
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const allowedFileTypes = ["image/jpeg", "image/png"];
  const maxFileSize = 200 * 1024; // 200 KB in bytes
  const requiredWidth = 300;
  const requiredHeight = 300;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileError(null);

    if (file) {
      if (file.size > maxFileSize) {
        setFileError("File size must be less than 200 KB.");
        setSelectedFile(null);
        setFileName("");
        return;
      }
      if (!allowedFileTypes.includes(file.type)) {
        setFileError("File must be a JPG or PNG image.");
        setSelectedFile(null);
        setFileName("");
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width !== requiredWidth || img.height !== requiredHeight) {
          setFileError("Image dimensions must be 300x300 pixels.");
          setSelectedFile(null);
          setFileName("");
        } else {
          setSelectedFile(file);
          setFileName(file.name); // Set the file name here
        }
      };

      // Clean up the object URL
      return () => {
        URL.revokeObjectURL(img.src);
      };
    } else {
      setSelectedFile(null);
      setFileName("");
    }
  };

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const [leafRes, unitRes, typeRes, supplierRes] = await Promise.all([
          fetchLeafs(),
          fetchUnits(),
          fetchTypes(),
          fetchSuppliers(),
        ]);

        setLeafOptions(
          leafRes.map((leaf) => ({
            value: leaf.id,
            label: `${leaf.type} (${leaf.total_number})`,
          }))
        );
        setUnitOptions(
          unitRes.map((unit) => ({ value: unit.id, label: unit.name }))
        );
        setTypeOptions(
          typeRes.map((type) => ({ value: type.id, label: type.name }))
        );
        setSupplierOptions(
          supplierRes.map((supplier) => ({
            value: supplier.id,
            label: `${supplier.name} (${supplier.mobile})`,
          }))
        );
      } catch (error) {
        console.error("Error loading options:", error);
      }
    };
    loadOptions();
  }, []);

  // Load initial data
  useEffect(() => {
    if (
      medicine &&
      leafOptions.length &&
      unitOptions.length &&
      typeOptions.length &&
      supplierOptions.length
    ) {
      setFormData({
        name: medicine.name || "",
        strength: medicine.strength || "",
        generic_name: medicine.generic_name || "",
        box_size: medicine.box_size || "",
        unit_name: medicine.unit_name || "",
        shelf: medicine.shelf || "",
        details: medicine.details || "",
        medicine_type: medicine.medicine_type || "",
        price: medicine.price || "",
        supplier_id: medicine.supplier_id || "",
        supplier_price: medicine.supplier_price || "",
        tax_a: medicine.tax_a || "5",
        tax_b: medicine.tax_b || "10",
      });
      setSelectedLeaf(
        leafOptions.find((leaf) => leaf.label === medicine.box_size) || null
      );
      setSelectedUnit(
        unitOptions.find((unit) => unit.label === medicine.unit_name) || null
      );
      setSelectedType(
        typeOptions.find((type) => type.label === medicine.medicine_type) ||
          null
      );
      setSelectedSupplier(
        supplierOptions.find(
          (supplier) => supplier.label === medicine.supplier_id
        ) || null
      );
    }
  }, [medicine, supplierOptions, leafOptions, unitOptions, typeOptions]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMedicineSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataObj = new FormData();

    // Add the form data
    formDataObj.append("name", formData.name);
    formDataObj.append("strength", formData.strength);
    formDataObj.append("generic_name", formData.generic_name);
    formDataObj.append("box_size", formData.box_size);
    formDataObj.append("unit_name", formData.unit_name);
    formDataObj.append("shelf", formData.shelf);
    formDataObj.append("details", formData.details);
    formDataObj.append("medicine_type", formData.medicine_type);
    formDataObj.append("price", formData.price);
    formDataObj.append("supplier_id", formData.supplier_id);
    formDataObj.append("supplier_price", formData.supplier_price);
    formDataObj.append("tax_a", formData.tax_a);
    formDataObj.append("tax_b", formData.tax_b);

    // Check if an image is selected and append it to form data
    if (selectedFile) {
      formDataObj.append("image", selectedFile);
    }

    try {
      // Use FormData to send as multipart/form-data
      await editMedicine(medicine.id, formDataObj);
      Swal.fire("Success!", "Medicine updated successfully.", "success");
      navigate("/medicinelist"); // Navigate to your medicines page
    } catch (error) {
      console.error("Error updating medicine:", error);
      Swal.fire(
        "Error!",
        "An error occurred while updating the medicine.",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="mb-5 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-primary ">Edit Medicine</h2>
          <div>
            <Link to="/medicinelist">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <FaBars />
                </span>
                Medicine List
              </p>
            </Link>
          </div>
        </div>
        <form
          className="grid grid-cols-2 gap-5"
          onSubmit={handleMedicineSubmit}
        >
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Medicine Name*
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Strength</label>
            <input
              type="text"
              name="strength"
              value={formData.strength}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Generic Name*
            </label>
            <input
              type="text"
              name="generic_name"
              value={formData.generic_name}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
              required
            />
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="box_size" class="text-primary font-semibold mb-1">
                Box Size*
              </label>
              <Select
                options={leafOptions}
                value={selectedLeaf}
                onChange={(option) => {
                  setSelectedLeaf(option);
                  setFormData((prevData) => ({
                    ...prevData,
                    box_size: option.label,
                  }));
                }}
                placeholder="Edit Box Size"
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderRadius: "8px",
                    boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#0090FF",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A0AEC0",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                    color: state.isFocused ? "#000" : "#333",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333",
                  }),
                }}
              />
            </div>
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="unit" class="text-primary font-semibold mb-1">
                Unit
              </label>
              <Select
                options={unitOptions}
                value={selectedUnit}
                onChange={(option) => {
                  setSelectedUnit(option);
                  setFormData((prevData) => ({
                    ...prevData,
                    unit_name: option.label,
                  }));
                }}
                placeholder="Edit Unit Name"
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderRadius: "8px",
                    boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#0090FF",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A0AEC0",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                    color: state.isFocused ? "#000" : "#333",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333",
                  }),
                }}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Shelf</label>
            <input
              type="text"
              name="shelf"
              value={formData.shelf}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Medicine Details
            </label>
            <input
              type="text"
              name="details"
              value={formData.details}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
            />
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="type" class="text-primary font-semibold mb-1">
                Medicine Type*
              </label>
              <Select
                options={typeOptions}
                value={selectedType}
                onChange={(option) => {
                  setSelectedType(option);
                  setFormData((prevData) => ({
                    ...prevData,
                    medicine_type: option.label,
                  }));
                }}
                placeholder="Edit Medicine Type"
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderRadius: "8px",
                    boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#0090FF",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A0AEC0",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                    color: state.isFocused ? "#000" : "#333",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333",
                  }),
                }}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Price*</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
              required
            />
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="supplier" class="text-primary font-semibold mb-1">
                Supplier*
              </label>
              <Select
                options={supplierOptions}
                value={selectedSupplier}
                onChange={(option) => {
                  setSelectedSupplier(option);
                  setFormData((prevData) => ({
                    ...prevData,
                    supplier_id: option.value,
                  }));
                }}
                placeholder="Edit Supplier Name"
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderRadius: "8px",
                    boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#0090FF",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A0AEC0",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                    color: state.isFocused ? "#000" : "#333",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333",
                  }),
                }}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Image</label>
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              ref={fileInputRef}
              className="px-2 py-1 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
              accept=".jpg, .jpeg, .png"
            />
            {fileError && (
              <p className="text-red-500 text-sm -mb-6">{fileError}</p>
            )}
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label className="text-primary font-semibold mb-1">Tax A %</label>
              <input
                type="text"
                name="tax_a"
                value={formData.tax_a}
                onChange={handleInputChange}
                placeholder=""
                className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
                readOnly
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Supplier Price*
            </label>
            <input
              type="number"
              name="supplier_price"
              value={formData.supplier_price}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Tax B %</label>
            <input
              type="text"
              name="tax_b"
              value={formData.tax_b}
              onChange={handleInputChange}
              placeholder=""
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
              readOnly
            />
          </div>

          <div className="col-span-full flex justify-center w-full">
            <button
              type="submit"
              className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default EditMedicine;

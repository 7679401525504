import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import Loader from "../../components/Loader";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoIosCopy } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdReceipt } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaFileExcel } from "react-icons/fa6";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { IoIosPrint } from "react-icons/io";
import { FaFileCsv } from "react-icons/fa";
import { HiMiniViewColumns } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import copy from "copy-to-clipboard";
import Swal from "sweetalert2";
import PopupReceive from "../../components/Popup/Customer/PopupReceive";
import PopupReturnDue from "../../components/Popup/Customer/PopupReturnDue";
import { fetchCustomers, editCustomer, deleteCustomer } from "../../api/api";

// PopupReceive component

const CustomerList = () => {
  useEffect(() => {
    document.title = "Customer List";
  }, []);
  const [actionDropdowns, setActionDropdowns] = useState({});
  const [isReceivePopupOpen, setReceivePopupOpen] = useState(false);
  const [isReturnDuePopupOpen, setReturnDuePopupOpen] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [checkedItems, setCheckedItems] = useState({});
  const [isColumnsDropdownOpen, setIsColumnsDropdownOpen] = useState(false);
  // fetch customer
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const tableRef = useRef();
  const columnsRef = useRef();

  const navigate = useNavigate(); // Use useNavigate

  const handleEditCustomer = (customer) => {
    // Navigate to the EditCustomer page and pass the customer data
    navigate(`/editcustomer/${customer.id}`, { state: { customer } });
  };

  const handleToggleStatus = async (customerId, currentStatus) => {
    const updatedStatus = currentStatus === 1 ? 0 : 1; // Toggle status

    // Optimistically update the UI before the server responds
    setCustomers((prevCustomers) =>
      prevCustomers.map((customer) =>
        customer.id === customerId
          ? { ...customer, status: updatedStatus }
          : customer
      )
    );

    try {
      const response = await editCustomer(customerId, {
        status: updatedStatus,
      });

      // Log the server response for debugging
      console.log("Server response:", response);

      // Optionally, check the server's response and log any issues
      if (
        response &&
        response.customer &&
        response.customer.status !== updatedStatus
      ) {
        console.error("Server did not update the status as expected.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      // Revert the UI change if the request fails
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer.id === customerId
            ? { ...customer, status: currentStatus }
            : customer
        )
      );
    }
  };

  const handleDeleteCustomer = async () => {
    // Convert selectedCustomerIds to numbers
    const selectedCustomerIds = Object.keys(checkedItems)
      .filter((id) => checkedItems[id])
      .map(Number); // Ensure IDs are numbers

    if (selectedCustomerIds.length === 0) {
      Swal.fire(
        "No Selection",
        "Please select at least one customer to delete.",
        "info"
      );
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text:
        selectedCustomerIds.length === 1
          ? "Do you want to delete this customer?"
          : `Do you want to delete ${selectedCustomerIds.length} customers?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Show a loading indicator
          Swal.fire({
            title: "Deleting...",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          // Perform deletion for each selected customer
          const deletePromises = selectedCustomerIds.map((id) =>
            deleteCustomer(id)
          );

          await Promise.all(deletePromises);

          // After successful deletion, show success message
          Swal.fire(
            "Deleted!",
            selectedCustomerIds.length === 1
              ? "The selected customer has been deleted."
              : "The selected customers have been deleted.",
            "success"
          );

          // ** Update the customers state by removing deleted customers **
          setCustomers((prevCustomers) => {
            return prevCustomers.filter(
              (customer) => !selectedCustomerIds.includes(customer.id)
            );
          });

          // Clear checked items after deletion
          setCheckedItems((prevChecked) => {
            const updatedChecked = { ...prevChecked };
            selectedCustomerIds.forEach((id) => {
              delete updatedChecked[id];
            });
            return updatedChecked;
          });
        } catch (error) {
          console.error("Deletion error:", error);
          Swal.fire(
            "Error!",
            "There was an error deleting the selected customers.",
            "error"
          );
        }
      }
    });
  };

  /**
   * Handle deletion of a single customer from the action dropdown
   * @param {string} customerId - ID of the customer to delete
   */

  const handleDeleteSingleCustomer = async (customerId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this customer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Show a loading indicator
          Swal.fire({
            title: "Deleting...",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          await deleteCustomer(customerId);

          // After successful deletion, show success message
          Swal.fire(
            "Deleted!",
            "The selected customer has been deleted.",
            "success"
          );

          // Update the customers state by removing the deleted customer
          setCustomers((prevCustomers) =>
            prevCustomers.filter((customer) => customer.id !== customerId)
          );

          // Remove the customer from checkedItems if it was checked
          setCheckedItems((prevChecked) => {
            const updatedChecked = { ...prevChecked };
            delete updatedChecked[customerId];
            return updatedChecked;
          });
        } catch (error) {
          console.error("Deletion error:", error);
          Swal.fire(
            "Error!",
            "There was an error deleting the selected customer.",
            "error"
          );
        }
      }
    });
  };
  /**
   * Handle selecting or deselecting all customers
   */
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    const newCheckedItems = {};
    customers.forEach((customer) => {
      newCheckedItems[customer.id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  /**
   * Handle selecting or deselecting an individual customer
   */
  const handleIndividualCheckbox = (customerId) => {
    setCheckedItems((prevChecked) => ({
      ...prevChecked,
      [customerId]: !prevChecked[customerId],
    }));
  };

  const isDeleteButtonVisible = Object.values(checkedItems).some(Boolean);

  const toggleActionDropdown = (customerId) => {
    setActionDropdowns((prev) => {
      // Check if the dropdown for the clicked customer is already open
      const isOpen = prev[customerId] || false;
      // Close all dropdowns and open the one that was clicked
      return { [customerId]: !isOpen };
    });
  };

  const handleOpenReceivePopup = (customer) => {
    setActiveCustomer(customer);
    setReceivePopupOpen(true);
  };

  const handleOpenReturnDuePopup = (customer) => {
    setActiveCustomer(customer);
    setReturnDuePopupOpen(true);
  };

  const handleCloseReceivePopup = () => {
    setReceivePopupOpen(false);
  };

  const handleCloseReturnDuePopup = () => {
    setReturnDuePopupOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (columnsRef.current && !columnsRef.current.contains(event.target)) {
        setIsColumnsDropdownOpen(false);
      }

      if (!event.target.closest(".action-dropdown")) {
        setActionDropdowns({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Search Functionality
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Execute the search filtering here
    }, 300); // 300ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // Pagination Logic

  const filteredCustomers = customers.filter((customer) => {
    const searchTermLower = searchTerm.toLowerCase();
    const formattedCustomerId = "CU" + String(customer.id);

    return (
      customer.name.toLowerCase().includes(searchTermLower) ||
      // String(customer.id).toLowerCase().includes(searchTermLower) ||
      formattedCustomerId.toLowerCase().includes(searchTermLower) ||
      customer.email.toLowerCase().includes(searchTermLower) ||
      (customer.mobile &&
        customer.mobile.toString().toLowerCase().includes(searchTermLower)) || // Added check for mobile
      (customer.previous_due &&
        customer.previous_due
          .toString()
          .toLowerCase()
          .includes(searchTermLower)) || // Sales Due
      (customer.salesReturnDue &&
        customer.salesReturnDue
          .toString()
          .toLowerCase()
          .includes(searchTermLower)) // If applicable
    );
  });

  const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);
  const paginatedCustomers = filteredCustomers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Generate page numbers with ellipsis logic
  const generatePageNumbers = () => {
    const pageNumbers = [];

    // Always show the first page
    pageNumbers.push(1);

    // Check if there are more than two pages
    if (totalPages > 2) {
      // Add ellipses if current page is more than 1 and less than totalPages
      if (currentPage > 1 && currentPage < totalPages) {
        pageNumbers.push("...");
      }

      // Add the current page only if it's not the first or last
      if (currentPage > 1 && currentPage < totalPages) {
        pageNumbers.push(currentPage);
      }

      // Always show the last page if it's not already shown
      if (currentPage < totalPages) {
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  // CSV Headers
  const csvHeaders = [
    { label: "Customer ID", key: "id" },
    { label: "Name", key: "name" },
    { label: "Mobile", key: "mobile" },
    { label: "Email", key: "email" },
    { label: "Total Paid", key: "totalPaid" },
    { label: "Sales Due", key: "salesDue" },
    { label: "Sales Return Due", key: "salesReturnDue" },
    { label: "Status", key: "status" },
  ];

  // Prepare CSV Data with Totals
  const csvData = [...filteredCustomers];

  // Calculate totals
  const totalPaid = customers.reduce(
    (sum, customer) => sum + parseFloat(customer.totalPaid) || 0,
    0
  );
  const salesDue = customers.reduce(
    (sum, customer) => sum + parseFloat(customer.salesDue) || 0,
    0
  );
  const salesReturnDue = customers.reduce(
    (sum, customer) => sum + parseFloat(customer.salesReturnDue) || 0,
    0
  );

  // Append totals row for CSV
  csvData.push({
    id: "",
    name: "Total",
    mobile: "",
    email: "",
    totalPaid: totalPaid.toLocaleString(),
    salesDue: salesDue.toLocaleString(),
    salesReturnDue: salesReturnDue.toLocaleString(),
    status: "",
  });

  const csvReport = {
    data: csvData,
    headers: csvHeaders,
    filename: "customers.csv",
  };

  // Excel Export with Totals
  const handleExportExcel = () => {
    const worksheetData = filteredCustomers.map((customer) => ({
      "Customer ID": customer.id,
      Name: customer.name,
      Mobile: customer.mobile,
      Email: customer.email,
      "Total Paid": parseFloat(customer.totalPaid),
      "Sales Due": parseFloat(customer.salesDue),
      "Sales Return Due": parseFloat(customer.salesReturnDue),
      Status: customer.status,
    }));

    // Calculate totals
    const totalPaid = customers.reduce(
      (sum, customer) => sum + parseFloat(customer.totalPaid) || 0,
      0
    );
    const salesDue = customers.reduce(
      (sum, customer) => sum + parseFloat(customer.salesDue) || 0,
      0
    );
    const salesReturnDue = customers.reduce(
      (sum, customer) => sum + parseFloat(customer.salesReturnDue) || 0,
      0
    );

    // Append totals row
    worksheetData.push({
      "Customer ID": "",
      Name: "Total",
      Mobile: "",
      Email: "",
      "Total Paid": totalPaid,
      "Sales Due": salesDue,
      "Sales Return Due": salesReturnDue,
      Status: "",
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "customers.xlsx");
  };

  // PDF Export with Totals
  const handleExportPDF = () => {
    const doc = new jsPDF();

    const tableColumn = [
      "Customer ID",
      "Name",
      "Mobile",
      "Email",
      "Total Paid",
      "Sales Due",
      "Sales Return Due",
      "Status",
    ];
    const tableRows = [];

    filteredCustomers.forEach((customer) => {
      const customerData = [
        customer.id,
        customer.name,
        customer.mobile,
        customer.email,
        parseFloat(customer.totalPaid).toLocaleString(),
        parseFloat(customer.salesDue).toLocaleString(),
        parseFloat(customer.salesReturnDue).toLocaleString(),
        customer.status,
      ];
      tableRows.push(customerData);
    });

    // Calculate totals
    const totalPaid = customers
      .reduce((sum, customer) => sum + parseFloat(customer.totalPaid) || 0, 0)
      .toLocaleString();
    const salesDue = customers
      .reduce(
        (sum, customer) => sum + parseFloat(customer.previous_due) || 0,
        0
      )
      .toLocaleString();
    const salesReturnDue = customers
      .reduce(
        (sum, customer) => sum + parseFloat(customer.salesReturnDue) || 0,
        0
      )
      .toLocaleString();

    // Append totals row
    const totalsRow = [
      "",
      "Total",
      "",
      "",
      totalPaid,
      salesDue,
      salesReturnDue,
      "",
    ];
    tableRows.push(totalsRow);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [41, 128, 185] }, // Optional: Customize header style
    });

    doc.text("Customer List", 14, 15);
    doc.save("customers.pdf");
  };

  // Copy to Clipboard with Totals
  const handleCopy = () => {
    let tableText =
      "Customer ID\tName\tMobile\tEmail\tTotal Paid\tSales Due\tSales Return Due\tStatus\n";
    filteredCustomers.forEach((customer) => {
      tableText += `${customer.id}\t${customer.name}\t${customer.mobile}\t${customer.email}\t${customer.totalPaid}\t${customer.salesDue}\t${customer.salesReturnDue}\t${customer.status}\n`;
    });

    // Calculate totals
    const totalPaid = customers
      .reduce((sum, customer) => sum + parseFloat(customer.totalPaid) || 0, 0)
      .toLocaleString();
    const salesDue = customers
      .reduce((sum, customer) => sum + parseFloat(customer.salesDue) || 0, 0)
      .toLocaleString();
    const salesReturnDue = customers
      .reduce(
        (sum, customer) => sum + parseFloat(customer.salesReturnDue) || 0,
        0
      )
      .toLocaleString();

    // Append totals row
    tableText += `\tTotal\t\t\t${totalPaid}\t${salesDue}\t${salesReturnDue}\t\n`;

    copy(tableText);
    alert("Table data with totals copied to clipboard!");
  };

  // Print Table (includes tfoot)
  const handlePrint = () => {
    const printContent = tableRef.current.outerHTML; // Using outerHTML to get the entire table including tfoot
    const originalContent = document.body.innerHTML;

    // Create a new window for printing
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write(`
      <html>
        <head>
          <title>Customer List</title>
          <style>
            /* Add any styles you need for the print */
            table {
              width: 100%; 
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #ddd; 
              padding: 8px;
            }
            th {
              background-color: #f2f2f2;
            }
            tfoot {
              font-weight: bold;
            }
            @media print {
              body {
                font-family: Arial, sans-serif;
                font-size: 14px;
              }
            }
          </style>
        </head>
        <body>
         <h1>Customer List</h1>
          ${printContent}
        </body>
      </html>
    `);

    // Trigger the print dialog
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close(); // Close the print window after printing

    // No need to reload the page
  };

  // Column Visibility
  const columns = [
    { label: "Select", key: "select", visible: true },
    { label: "Customer ID", key: "id", visible: true },
    { label: "Customer Name", key: "name", visible: true },
    { label: "Mobile", key: "mobile", visible: true },
    { label: "Email", key: "email", visible: true },
    { label: "Total Paid", key: "totalPaid", visible: true },
    { label: "Sales Due", key: "salesDue", visible: true },
    { label: "Sales Return Due", key: "salesReturnDue", visible: true },
    { label: "Status", key: "status", visible: true },
    { label: "Action", key: "action", visible: true },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.reduce((acc, column) => {
      acc[column.key] = column.visible;
      return acc;
    }, {})
  );

  const toggleColumn = (columnKey) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  };

  // const [isColumnsDropdownOpen, setIsColumnsDropdownOpen] = useState(false);

  const toggleColumnsDropdown = () => {
    setIsColumnsDropdownOpen((prev) => !prev);
  };

  //   // Fetch customers when the component mounts
  useEffect(() => {
    const getCustomers = async () => {
      try {
        setLoading(true); // Set loading to true before fetching
        const customerData = await fetchCustomers();
        setCustomers(customerData); // Set the customers into state
      } catch (err) {
        setError(err); // Handle the error
      } finally {
        setLoading(false); // Stop the loading spinner
      }
    };

    getCustomers(); // Trigger the fetch
  }, []);

  // If loading, show a loading message
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  // If there is an error, display the error message
  if (error) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-primary">Customer List</h2>
          <div>
            <Link to="/newcustomer">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <BsFillPlusCircleFill />
                </span>
                New Customer
              </p>
            </Link>
          </div>
        </div>
        {/* Table Controls */}
        <div className="flex justify-between items-center mb-4 dropdown">
          <div className="flex justify-between items-center gap-3 font-semibold text-primary">
            <p>Show</p>
            <select
              className="flex  items-center border custom-border w-24 px-1 py-1 focus:outline-none cursor-pointer"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange} // Made dynamic
            >
              <option
                value={5}
                className="hover:bg-white cursor-pointer custom-border"
              >
                5
              </option>
              <option
                value={10}
                className="hover:bg-white cursor-pointer custom-border"
              >
                10
              </option>
              <option
                value={15}
                className="hover:bg-white cursor-pointer custom-border"
              >
                15
              </option>
            </select>
            {/* <p>Entries</p> */}
          </div>
          <div className="w-24">
            {isDeleteButtonVisible && (
              <div
                className="flex justify-between text-center items-center bg-red-600 custom-border px-2 py-1"
                onClick={handleDeleteCustomer}
              >
                <button className=" text-white hover:text-gray-300 py-1 px-2 cursor-pointer  flex items-center gap-1">
                  Delete{" "}
                  <span>
                    <RiDeleteBin6Fill />
                  </span>
                </button>
              </div>
            )}
          </div>
          <div className="flex justify-between text-center items-center bg-primary custom-border px-2 py-1">
            <button
              className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1"
              onClick={handleCopy}
            >
              Copy{" "}
              <span>
                <IoIosCopy />
              </span>
            </button>
            <button
              className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1"
              onClick={handleExportExcel}
            >
              Excel{" "}
              <span>
                <FaFileExcel />
              </span>
            </button>
            <button
              className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1"
              onClick={handleExportPDF}
            >
              PDF{" "}
              <span>
                <BsFillFileEarmarkPdfFill />
              </span>
            </button>
            <button
              className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1"
              onClick={handlePrint}
            >
              Print{" "}
              <span>
                <IoIosPrint />
              </span>
            </button>
            <CSVLink {...csvReport}>
              <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
                CSV{" "}
                <span>
                  <FaFileCsv />
                </span>
              </button>
            </CSVLink>
            <button
              ref={columnsRef}
              className="relative  text-white  py-1 px-2 cursor-pointer flex items-center gap-1 "
              onClick={toggleColumnsDropdown}
            >
              <span className="hover:text-secondary">Columns</span>
              <span>
                <HiMiniViewColumns />
              </span>
              {isColumnsDropdownOpen && (
                <div className="absolute right-0 top-10 w-[155px]  custom-border bg-primary px-2 py-2 ">
                  {columns.map((column) => (
                    <div
                      key={column.key}
                      className="flex items-center text-sm hover:text-secondary"
                    >
                      <input
                        type="checkbox"
                        checked={visibleColumns[column.key]}
                        onChange={() => toggleColumn(column.key)}
                        className="mr-2"
                      />
                      <label>{column.label}</label>
                    </div>
                  ))}
                </div>
              )}
            </button>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="border custom-border bg-gray-300 text-primary font-semibold rounded-md p-2 focus:outline-none pl-8 focus:ring-0 focus:ring-blue-500"
              value={searchTerm}
              onChange={handleSearch}
            />
            <span className="absolute left-2 top-3 text-xl text-primary font-bold">
              <CiSearch />
            </span>
          </div>
        </div>
        {/* Table */}
        <table
          ref={tableRef}
          className="bg-white border border-gray-200 w-full"
        >
          <thead>
            <tr className="bg-primary text-white text-sm">
              {visibleColumns["select"] && (
                <th className="py-1 px-2 border-r">
                  <input
                    type="checkbox"
                    className="w-4 h-4"
                    checked={
                      customers.length > 0 &&
                      Object.keys(checkedItems).length === customers.length &&
                      Object.values(checkedItems).every(Boolean)
                    }
                    onChange={handleSelectAll}
                  />
                </th>
              )}
              {visibleColumns["id"] && (
                <th className="p-1 border-r">Customer ID</th>
              )}
              {visibleColumns["name"] && (
                <th className="p-1 border-r break-all">Customer Name</th>
              )}
              {visibleColumns["mobile"] && (
                <th className="p-1 border-r ">Mobile</th>
              )}
              {visibleColumns["email"] && (
                <th className="p-1 border-r ">Email</th>
              )}
              {visibleColumns["totalPaid"] && (
                <th className="p-1 border-r text-right">Total Paid</th>
              )}
              {visibleColumns["salesDue"] && (
                <th className="p-1 border-r text-right">Sales Due</th>
              )}
              {visibleColumns["salesReturnDue"] && (
                <th className="p-1 border-r text-right">Sales Return Due</th>
              )}
              {visibleColumns["status"] && (
                <th className="p-1 border-r text-center">Status</th>
              )}
              {visibleColumns["action"] && (
                <th className="p-1 border-r text-center">Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {paginatedCustomers.length > 0 ? (
              paginatedCustomers.map((customer) => (
                <tr key={customer.id} className="border-b">
                  {visibleColumns["select"] && (
                    <td className="py-1 px-2 text-center border-r">
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={!!checkedItems[customer.id]}
                        onChange={() => handleIndividualCheckbox(customer.id)}
                      />
                    </td>
                  )}
                  {visibleColumns["id"] && (
                    <td className="p-1 border-r text-gray-600">
                      {"CU" + String(customer.id)}
                    </td>
                  )}
                  {visibleColumns["name"] && (
                    <td className="p-1 border-r text-gray-600   break-all">
                      {customer.name}
                    </td>
                  )}
                  {visibleColumns["mobile"] && (
                    <td className="p-1 border-r text-gray-600">
                      {customer.mobile}
                    </td>
                  )}
                  {visibleColumns["email"] && (
                    <td className="p-1 border-r text-gray-600 break-all">
                      {customer.email}
                    </td>
                  )}
                  {visibleColumns["totalPaid"] && (
                    <td className="p-1 border-r text-right text-gray-600">
                      {parseFloat(customer.totalPaid).toLocaleString()}
                    </td>
                  )}
                  {visibleColumns["salesDue"] && (
                    <td className="p-1 border-r text-right text-gray-600">
                      {parseFloat(customer.previous_due).toLocaleString()}
                    </td>
                  )}
                  {visibleColumns["salesReturnDue"] && (
                    <td className="p-1 border-r text-right text-gray-600">
                      {parseFloat(customer.salesReturnDue).toLocaleString()}
                    </td>
                  )}
                  {visibleColumns["status"] && (
                    <td className="p-1 text-center border-r">
                      <span
                        className={`py-1 px-2 rounded text-white text-xs cursor-pointer ${
                          customer.status === 1 ? "bg-green-500" : "bg-red-500"
                        }`}
                        onClick={() =>
                          handleToggleStatus(customer.id, customer.status)
                        }
                      >
                        {customer.status === 1 ? "Active" : "Inactive"}
                      </span>
                    </td>
                  )}
                  {visibleColumns["action"] && (
                    <td className="p-1 text-center action-dropdown">
                      <div className="flex justify-center">
                        <div className="relative">
                          <button
                            className="bg-primary text-sm text-white py-1 px-2 gap-1 rounded-md hover:bg-secondary flex justify-center items-center"
                            onClick={() => toggleActionDropdown(customer.id)}
                          >
                            Action
                            {actionDropdowns[customer.id] ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )}
                          </button>
                          {actionDropdowns[customer.id] && (
                            <div className="absolute bg-primary custom-border right-0 w-48 z-10 p-2 text-start text-white text-sm list-none">
                              <ul>
                                <li
                                  className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                  onClick={() => handleEditCustomer(customer)}
                                >
                                  <FaEdit /> Edit
                                </li>
                                <li
                                  className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                  onClick={() =>
                                    handleOpenReceivePopup(customer)
                                  }
                                >
                                  <MdReceipt /> Receive Due Payments
                                </li>
                                <li
                                  className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                  onClick={() =>
                                    handleOpenReturnDuePopup(customer)
                                  }
                                >
                                  <MdReceipt /> Pay Return Due
                                </li>
                                <li
                                  className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                  onClick={() =>
                                    handleDeleteSingleCustomer(customer.id)
                                  }
                                >
                                  <RiDeleteBin6Fill /> Delete
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="py-4 px-4 text-center text-red-500 font-semibold"
                  colSpan={visibleColumns["select"] ? "10" : "9"}
                >
                  Sorry, No customers found!
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr className="bg-gray-300 text-primary ">
              {visibleColumns["select"] && (
                <td
                  className="p-2 border-r text-right font-semibold"
                  colSpan="5"
                >
                  Total
                </td>
              )}
              {visibleColumns["totalPaid"] && (
                <td className="p-1 border-r text-right font-semibold">
                  {customers
                    .reduce(
                      (sum, customer) =>
                        sum + parseFloat(customer.totalPaid) || 0,
                      0
                    )
                    .toLocaleString()}
                </td>
              )}
              {visibleColumns["salesDue"] && (
                <td className="p-1 border-r text-right font-semibold">
                  {customers
                    .reduce(
                      (sum, customer) =>
                        sum + parseFloat(customer.salesDue) || 0,
                      0
                    )
                    .toLocaleString()}
                </td>
              )}
              {visibleColumns["salesReturnDue"] && (
                <td className="p-1 border-r text-right font-semibold">
                  {customers
                    .reduce(
                      (sum, customer) =>
                        sum + parseFloat(customer.salesReturnDue) || 0,
                      0
                    )
                    .toLocaleString()}
                </td>
              )}
              {visibleColumns["status"] && (
                <td className="p-1" colSpan="2"></td>
              )}
            </tr>
          </tfoot>
        </table>
        {/* Pagination */}
        <div className="flex justify-between items-center mt-4 ">
          <p className="text-primary font-semibold">
            Showing{" "}
            {filteredCustomers.length > 0
              ? (currentPage - 1) * itemsPerPage + 1
              : 0}{" "}
            to{" "}
            {currentPage * itemsPerPage > filteredCustomers.length
              ? filteredCustomers.length
              : currentPage * itemsPerPage}{" "}
            of {filteredCustomers.length} entries
          </p>
          <div className="flex justify-end">
            <div className="flex justify-between items-center w-full bg-blue-200 custom-border px-4 py-1 space-x-2  mx-auto">
              {/* Previous Button */}
              <button
                className={`px-3 py-1 custom-border ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                    : "bg-blue-200 text-primary font-semibold hover:bg-blue-300"
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              {/* Generate Page Numbers with Ellipses */}
              {generatePageNumbers().map((page, index) => (
                <button
                  key={index}
                  className={`px-7 py-1 font-semibold custom-border ${
                    currentPage === page
                      ? "bg-primary text-white"
                      : "bg-blue-200 text-primary hover:bg-blue-300"
                  }`}
                  onClick={() =>
                    typeof page === "number" && handlePageChange(page)
                  }
                  disabled={typeof page !== "number"} // Disable ellipses
                >
                  {page}
                </button>
              ))}

              {/* Next Button */}
              <button
                className={`px-3 py-1 custom-border ${
                  currentPage === totalPages || totalPages === 0
                    ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                    : "bg-blue-200 text-primary font-semibold hover:bg-blue-300"
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 0}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        <PopupReceive
          isOpen={isReceivePopupOpen}
          onClose={handleCloseReceivePopup}
          customer={activeCustomer}
        />

        <PopupReturnDue
          isOpen={isReturnDuePopupOpen}
          onClose={handleCloseReturnDuePopup}
          customer={activeCustomer}
        />
      </div>
    </DashboardLayout>
  );
};

export default CustomerList;

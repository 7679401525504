import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const SalesReturnReport = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>SalesReturnReport</div>
    </DashboardLayout>
  );
};

export default SalesReturnReport;

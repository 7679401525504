import { useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";

const PopupReturnDue = ({ isOpen, onClose, customer }) => {
  // if (!isOpen || !customer) return null;
  useEffect(() => {
    if (isOpen) {
      // Add Tailwind overflow-hidden class to body when popup is open
      document.body.classList.add("overflow-hidden");
    } else {
      // Remove Tailwind overflow-hidden class from body when popup is closed
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up on unmount or when popup is closed
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  if (!isOpen || !customer) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
      <div className="relative bg-white z-30 custom-border p-4 w-[33%]">
        <h2 className="text-center text-lg text-primary font-semibold">
          Pay Return Due
        </h2>
        <div className="absolute right-2 top-2  ">
          <span
            title="Close"
            className="text-3xl text-primary hover:text-red-600 cursor-pointer"
            onClick={onClose}
          >
            <IoIosCloseCircle />
          </span>
        </div>
        <div className="flex justify-start">
          <div className="font-semibold leading-5 text-sm">
            <p className="text-gray-500">Customer Details</p>
            <p className="break-all">
              {" "}
              <span className="text-gray-500">Name: </span>
              {customer.name}
            </p>
            <p>
              <span className="text-gray-500">Mobile: </span>
              {customer.mobile}
            </p>
            <p>
              <span className="text-gray-500 ">Email: </span>
              {customer.email}
            </p>
          </div>
        </div>
        <div className="bg-gray-300 rounded-lg p-2 w-full flex justify-center text-sm gap-5 font-semibold my-2">
          <div>
            <p className="flex justify-between gap-10 items-center w-full">
              Total Sales Amount <span className="text-primary">$0.00</span>
            </p>
            <p className="flex justify-between gap-10 items-center w-full">
              Paid Payment<span className="text-primary">$0.00</span>
            </p>
            <p className="flex justify-between gap-10 items-center w-full">
              Sales Due
              <span className="text-primary">
                {parseFloat(customer.salesDue).toLocaleString()}
              </span>
            </p>
          </div>
        </div>
        <div className="leading-6 bg-primary text-sm px-3 py-1 rounded-lg">
          <div className="flex space-x-2">
            <div className="flex-1">
              <label className="block text-white">Date</label>
              <div className="flex items-center">
                <input
                  type="date"
                  name="date"
                  className="w-full px-2 py-[0.2rem] border border-gray-300 rounded-md focus:outline-none "
                />
              </div>
            </div>
            <div className="flex-1">
              <label className="block text-white">Amount</label>
              <input
                type="number"
                name="amount"
                placeholder="Enter amount"
                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none "
              />
            </div>
          </div>

          <div>
            <label className="block text-white">Payment Type</label>
            <select
              name="paymentType"
              className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none "
            >
              <option value="Cash">Cash</option>
              <option value="Credit Card">Credit Card</option>
              <option value="Bank Transfer">Bank Transfer</option>
            </select>
          </div>

          <div>
            <label className="block text-white">Payment Note</label>
            <textarea
              name="paymentNote"
              placeholder="Enter payment note"
              className="w-full h-20 px-2 py-1 border border-gray-300 rounded-md focus:outline-none "
            ></textarea>
          </div>
        </div>
        <div className="flex justify-center gap-5 mt-3">
          <button
            type="submit"
            className="px-8 py-1 bg-primary text-white rounded-md hover:bg-secondary"
          >
            Save
          </button>
          <button
            type="button"
            className="px-8 py-1 bg-red-600 text-white rounded-md hover:bg-red-800"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default PopupReturnDue;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const SMSTemplate = () => {
  return (
    <DashboardLayout>
      <div>SMSTemplate</div>
    </DashboardLayout>
  );
};

export default SMSTemplate;

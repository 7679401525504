import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const StockReport = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>StockReport</div>
    </DashboardLayout>
  );
};

export default StockReport;

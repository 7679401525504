import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login";
import NewCustomer from "../pages/Customer/NewCustomer";
import CustomerList from "../pages/Customer/CustomerList";
import NewSupplier from "../pages/Supplier/NewSupplier";
import SupplierList from "../pages/Supplier/SupplierList";
import AddMedicine from "../pages/Medicine/AddMedicine";
import MedicineList from "../pages/Medicine/MedicineList";
import AddUnit from "../pages/Medicine/AddUnit";
import UnitList from "../pages/Medicine/UnitList";
import AddType from "../pages/Medicine/AddType";
import TypeList from "../pages/Medicine/TypeList";
import LeafSetting from "../pages/Medicine/LeafSetting";
import AddPurchase from "../pages/Purchase/AddPurchase";
import PurchaseList from "../pages/Purchase/PurchaseList";
import NewSale from "../pages/Sale/NewSale";
import SalesList from "../pages/Sale/SalesList";
import AddReturn from "../pages/Return/AddReturn";
import SalesReturnList from "../pages/Return/SalesReturnList";
import PurchaseReturnList from "../pages/Return/PurchaseReturnList";
import WastageReturnList from "../pages/Return/WastageReturnList";
import NewExpense from "../pages/Expense/NewExpense";
import ExpenseList from "../pages/Expense/ExpenseList";
import NewCategory from "../pages/Expense/NewCategory";
import CategoryList from "../pages/Expense/CategoryList";
import ProfitLossReport from "../pages/Report/ProfitLossReport";
import PurchaseReport from "../pages/Report/PurchaseReport";
import PurchaseReturnReport from "../pages/Report/PurchaseReturnReport";
import SalesReport from "../pages/Report/SalesReport";
import SalesReturnReport from "../pages/Report/SalesReturnReport";
import ExpenseReport from "../pages/Report/ExpenseReport";
import ExpiredItemsReport from "../pages/Report/ExpiredItemsReport";
import StockReport from "../pages/Report/StockReport";
import UserSalesReport from "../pages/Report/UserSalesReport";
import ProductSalesReport from "../pages/Report/ProductSalesReport";
import NewUser from "../pages/Users/NewUser";
import UserList from "../pages/Users/UserList";
import RoleList from "../pages/Users/RoleList";
import SendSMS from "../pages/SMS/SendSMS";
import SMSTemplate from "../pages/SMS/SMSTemplate";
import SMSAPI from "../pages/SMS/SMSAPI";
import CompanyProfile from "../pages/Settings/CompanyProfile";
import SiteSettings from "../pages/Settings/SiteSettings";
import TaxList from "../pages/Settings/TaxList";
import UnitsList from "../pages/Settings/UnitsList";
import PaymentTypeList from "../pages/Settings/PaymentTypeList";
import CurrencyList from "../pages/Settings/CurrencyList";
import Language from "../pages/Settings/Language";
import ChangePassword from "../pages/Settings/ChangePassword";
import DatabaseBackup from "../pages/Settings/DatabaseBackup";
import Help from "../pages/Settings/Help";
import EditCustomer from "../pages/Customer/EditCustomer";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import EditSupplier from "../pages/Supplier/EditSupplier";
import EditMedicine from "../pages/Medicine/EditMedicine";
import EditUnit from "../pages/Medicine/EditUnit";
import EditType from "../pages/Medicine/EditType";
import EditLeaf from "../pages/Medicine/EditLeaf";

const AppRoutes = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay (or you can replace this with actual data loading)
    setTimeout(() => {
      setLoading(false); // Set loading to false after the loading finishes
    }, 2000); // Adjust the delay as needed
  }, []);

  if (loading) {
    return <Loader />; // Show the loader while loading
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Dashboard />} />
        {/* customer management */}
        <Route path="/newcustomer" element={<NewCustomer />} />
        <Route path="/editcustomer/:id" element={<EditCustomer />} />
        <Route path="/customerlist" element={<CustomerList />} />
        {/* supplier management */}
        <Route path="/newsupplier" element={<NewSupplier />} />
        <Route path="/editsupplier/:id" element={<EditSupplier />} />
        <Route path="/supplierlist" element={<SupplierList />} />
        {/* medicine management */}
        <Route path="/addmedicine" element={<AddMedicine />} />
        <Route path="/editmedicine/:id" element={<EditMedicine />} />
        <Route path="/medicinelist" element={<MedicineList />} />
        <Route path="/addunit" element={<AddUnit />} />
        <Route path="/editunit/:id" element={<EditUnit />} />
        <Route path="/unitlist" element={<UnitList />} />
        <Route path="/addtype" element={<AddType />} />
        <Route path="/edittype/:id" element={<EditType />} />
        <Route path="/typelist" element={<TypeList />} />
        <Route path="/leafsetting" element={<LeafSetting />} />
        <Route path="/editleaf/:id" element={<EditLeaf />} />
        <Route path="/addpurchase" element={<AddPurchase />} />
        <Route path="/purchaselist" element={<PurchaseList />} />
        <Route path="/newsale" element={<NewSale />} />
        <Route path="/saleslist" element={<SalesList />} />
        <Route path="/addreturn" element={<AddReturn />} />
        <Route path="/salesreturnlist" element={<SalesReturnList />} />
        <Route path="/purchasereturnlist" element={<PurchaseReturnList />} />
        <Route path="/wastagereturnlist" element={<WastageReturnList />} />
        <Route path="/newexpense" element={<NewExpense />} />
        <Route path="/expenselist" element={<ExpenseList />} />
        <Route path="/newcategory" element={<NewCategory />} />
        <Route path="/categorylist" element={<CategoryList />} />
        <Route path="/profitlossreport" element={<ProfitLossReport />} />
        <Route path="/purchasereport" element={<PurchaseReport />} />
        <Route
          path="/purchasereturnreport"
          element={<PurchaseReturnReport />}
        />
        <Route path="/salesreport" element={<SalesReport />} />
        <Route path="/salesreturnreport" element={<SalesReturnReport />} />
        <Route path="/expensereport" element={<ExpenseReport />} />
        <Route path="/expireditemsreport" element={<ExpiredItemsReport />} />
        <Route path="/stockreport" element={<StockReport />} />
        <Route path="/usersalesreport" element={<UserSalesReport />} />
        <Route path="/productsalesreport" element={<ProductSalesReport />} />
        <Route path="/newuser" element={<NewUser />} />
        <Route path="/userlist" element={<UserList />} />
        <Route path="/rolelist" element={<RoleList />} />
        <Route path="/sendsms" element={<SendSMS />} />
        <Route path="/smstemplate" element={<SMSTemplate />} />
        <Route path="/smsapi" element={<SMSAPI />} />
        <Route path="/companyprofile" element={<CompanyProfile />} />
        <Route path="/sitesettings" element={<SiteSettings />} />
        <Route path="/taxlist" element={<TaxList />} />
        <Route path="/unitslist" element={<UnitsList />} />
        <Route path="/paymenttypelist" element={<PaymentTypeList />} />
        <Route path="/currencylist" element={<CurrencyList />} />
        <Route path="/language" element={<Language />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/databasebackup" element={<DatabaseBackup />} />
        <Route path="/help" element={<Help />} />
      </Routes>
    </Router>
  );
};
export default AppRoutes;

import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa6";
import DashboardLayout from "../../layouts/DashboardLayout";
import { fetchTypes, editType } from "../../api/api"; // Import your API functions
import Swal from "sweetalert2";
import { Link, useLocation, useNavigate } from "react-router-dom";

const EditType = () => {
  useEffect(() => {
    document.title = "Edit Type";
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const { type } = location.state || {};

  const [formData, setFormData] = useState({
    name: "",
    status: "", // default status
  });

  useEffect(() => {
    if (type) {
      setFormData({
        name: type.name || "",
        status:
          type.status !== undefined && type.status !== null ? type.status : 1, // Only fallback to 1 if type.status is undefined or null
      });
    }
  }, [type]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "status" ? Number(value) : value, // Ensure status is numeric
    }));
  };

  // Handle form submission
  const handleTypeSubmit = async (e) => {
    e.preventDefault();

    try {
      await editType(type.id, formData); // Call the edittype API function

      // Show success popup
      Swal.fire({
        title: "Success!",
        text: "Type has been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });

      // Navigate back to the type list after successful update
      navigate("/typelist");
    } catch (error) {
      console.error("Error updating type:", error);

      // Show error popup
      Swal.fire({
        title: "Error!",
        text: "There was an error updating the type. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="mb-5 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-primary ">Edit Type</h2>
          <div>
            <Link to="/typelist">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <FaBars />
                </span>
                Type List
              </p>
            </Link>
          </div>
        </div>
        <form className="grid grid-cols gap-5 " onSubmit={handleTypeSubmit}>
          <div className="col-span-full flex justify-center w-full">
            <div className="flex flex-col w-96 ">
              <label className="text-primary font-semibold mb-1">
                Type Name*
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter Here"
                className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                required
                maxLength={50} // Example validation
                aria-label="Type Name" // Accessibility enhancement
              />

              <div className="flex gap-3 items-center mt-1 ">
                <p className="text-primary font-semibold">Status*</p>
                <div className="flex items-center ">
                  <input
                    id="active"
                    type="radio"
                    value={1}
                    name="status"
                    checked={formData.status === 1}
                    onChange={handleInputChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="active"
                    className="ms-2 text-sm font-medium text-primary dark:text-gray-300"
                  >
                    Active
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="inactive"
                    type="radio"
                    value={0}
                    name="status"
                    checked={formData.status === 0}
                    onChange={handleInputChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="inactive"
                    className="ms-2 text-sm font-medium text-primary dark:text-gray-300"
                  >
                    Inactive
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-full flex justify-center w-full">
            <button
              type="submit"
              className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default EditType;

import { useState } from 'react';
import { useAuth as useAuthContext } from '../context/AuthContext';  // Renamed the imported hook to avoid conflict
import { login as loginAPI } from '../services/AuthService';

const useAuth = () => {
  const { login } = useAuthContext();  // Use the renamed hook
  const [error, setError] = useState(null);

  const handleLogin = async (credentials) => {
    try {
      const userData = await loginAPI(credentials);
      login(userData);
    } catch (err) {
      setError('Invalid credentials');
    }
  };

  return { handleLogin, error };
};

export default useAuth;

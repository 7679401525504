import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const Language = () => {
  return (
    <DashboardLayout>
      {" "}
      <div>Language</div>
    </DashboardLayout>
  );
};

export default Language;

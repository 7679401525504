import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const SalesReturnList = () => {
  return (
    <DashboardLayout>
      <div>SalesReturnList</div>
    </DashboardLayout>
  );
};

export default SalesReturnList;

import axios from "axios";

// Set up the base URL for your Laravel API
const API_BASE_URL = "https://api-pharmacy.opt2cash.com/api/v1";

/**
 * Fetch all customers
 */
export const fetchCustomers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/cus_list`); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching customers:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

/**
 * Add a new customer
 * @param {Object} customerData - { name, email, mobile, address, previous_due }
 */
export const addCustomer = async (customerData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/cus_new`, customerData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding customer:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

/**
 * Edit a customer
 * @param {number} customerId - ID of the customer to be edited
 * @param {Object} updatedCustomerData - New data for the customer { name, email, mobile, address, previous_due }
 */
export const editCustomer = async (customerId, updatedCustomerData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/cus_upd/${customerId}`,
      updatedCustomerData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error editing customer:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

/**
 * Delete a customer
 * @param {number} customerId - ID of the customer to be deleted
 */
export const deleteCustomer = async (customerId) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/cus_del/${customerId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting customer:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

/**
 * Fetch all supplier
 */
export const fetchSuppliers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/sup_list`); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching suppliers:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const addSupplier = async (supplierData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sup_new`, supplierData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding supplier:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editSupplier = async (supplierId, updatedSupplierData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/sup_upd/${supplierId}`,
      updatedSupplierData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error editing supplier:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteSupplier = async (supplierId) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/sup_del/${supplierId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting supplier:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

//medicine section
export const fetchMedicines = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/med_list`); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching medicines:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const fetchMedicinesBySupplier = async (supplierId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/med_list/${supplierId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching medicines:", error.response?.data || error);
    throw error;
  }
};

export const addMedicine = async (medicineData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/med_new`, medicineData, {
      headers: {
        //  "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding medicine:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editMedicine = async (medicineId, updatedMedicineData) => {
  try {
    // Log each key-value pair in FormData
    for (let [key, value] of updatedMedicineData.entries()) {
      console.log(key, value);
    }

    const response = await axios.post(
      `${API_BASE_URL}/med_upd/${medicineId}`,
      updatedMedicineData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error editing medicine:",
      error.response ? error.response.data : error
    );
    throw new Error(
      error.response?.data?.message ||
        "Failed to update medicine. Please try again."
    );
  }
};

export const deleteMedicine = async (medicineId) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/med_del/${medicineId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting medicine:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// unit section
export const fetchUnits = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/unit_list`); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching unit:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const addUnit = async (unitData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/unit_new`, unitData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding unit:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editUnit = async (unitId, updatedUnitData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/unit_upd/${unitId}`,
      updatedUnitData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error editing unit:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteUnit = async (unitId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/unit_del/${unitId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting unit:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// type section
export const fetchTypes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/med_type_list`); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching types:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const addType = async (typeData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/med_type_new`,
      typeData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error adding type:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editType = async (typeId, updatedTypeData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/med_type_upd/${typeId}`,
      updatedTypeData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error editing type:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteType = async (typeId) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/med_type_del/${typeId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting type:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// leaf setting
export const fetchLeafs = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/leaf_list`); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching leafs:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const addLeaf = async (leafData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/leaf_new`, leafData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding leaf:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editLeaf = async (leafId, updatedLeafData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/leaf_upd/${leafId}`,
      updatedLeafData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error editing leaf:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteLeaf = async (leafId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/leaf_del/${leafId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting leaf:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

import React from "react";

const ExpiredItemsTable = () => {
  const expiredItems = [
    {
      code: "IT0001",
      name: "Amoxicillin and Clavulanate",
      category: "Antibiotic",
      expireDate: "30.05.2024",
    },
    {
      code: "IT0001",
      name: "Amoxicillin and Clavulanate",
      category: "Antibiotic",
      expireDate: "30.05.2024",
    },
    {
      code: "IT0001",
      name: "Amoxicillin and Clavulanate",
      category: "Antibiotic",
      expireDate: "30.05.2024",
    },
    {
      code: "IT0001",
      name: "Amoxicillin and Clavulanate",
      category: "Antibiotic",
      expireDate: "30.05.2024",
    },
  ];

  return (
    <div className="w-full ">
      <h1 className="text-center py-2 text-primary font-semibold">
        Expired Items
      </h1>
      <table className="min-w-full bg-white border-collapse">
        <thead>
          <tr className="text-left text-sm text-primary  bg-gray-200 ">
            <th className="p-3 border-b-2">Item Code</th>
            <th className="p-3 border-b-2">Item Name</th>
            <th className="p-3 border-b-2">Category Name</th>
            <th className="p-3 border-b-2">Expire Date</th>
          </tr>
        </thead>
        <tbody>
          {expiredItems.map((item, index) => (
            <tr key={index} className="text-sm border-b">
              <td className="p-3">{item.code}</td>
              <td className="p-3">{item.name}</td>
              <td className="p-3">{item.category}</td>
              <td className="p-3">{item.expireDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center pt-3">
        <a href="#" className="font-semibold text-secondary hover:text-primary">
          View All
        </a>
      </div>
    </div>
  );
};

export default ExpiredItemsTable;

import React, { useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const PurchaseList = () => {
  useEffect(() => {
    document.title = "Purchase List";
  }, []);

  // add supplier data popup
  // const [isSupplierSubmitting, setIsSupplierSubmitting] = useState(false);
  // const [error, setError] = useState(null); // To handle any API errors
  // const [successMessage, setSuccessMessage] = useState(null);
  // const [formSupplierErrors, setFormSupplierErrors] = useState({}); // State for form validation errors
  // const [nextSupplierID, setNextSupplierID] = useState("");
  // const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
  // const [formSupplierData, setFormSupplierData] = useState({
  //   id: "",
  //   name: "",
  //   mobile: "",
  //   email: "",
  //   brand: "",
  //   tax_number: "",
  //   opening_balance: 0,
  //   address: "",
  // });

  // useEffect(() => {
  //   // Disable scrolling when the modal is open
  //   if (isSupplierModalOpen) {
  //     document.body.classList.add("overflow-hidden");
  //   } else {
  //     document.body.classList.remove("overflow-hidden");
  //   }

  //   // Clean up function to remove the class if the component unmounts
  //   return () => {
  //     document.body.classList.remove("overflow-hidden");
  //   };
  // }, [isSupplierModalOpen]);

  // const fetchAndSetNextSupplierID = async () => {
  //   try {
  //     const suppliers = await fetchSuppliers(); // Fetch the supplier list
  //     console.log("Fetched suppliers:", suppliers); // Log the suppliers

  //     if (suppliers && suppliers.length > 0) {
  //       const lastSupplier = suppliers[suppliers.length - 1];
  //       const lastSupplierCode =
  //         lastSupplier.supplier_code || `SU${lastSupplier.id}`; // Use supplier ID directly if supplier_code is not present

  //       console.log("Last supplier code (fallback if null):", lastSupplierCode); // Log the fallback code

  //       const numericPart = parseInt(lastSupplierCode.slice(2), 10); // Parse the numeric part after "SU"
  //       const newID = `SU${numericPart + 1}`; // Generate new ID without leading zeros
  //       console.log("Next supplier ID:", newID); // Log the generated ID
  //       setNextSupplierID(newID); // Set the next supplier ID
  //     } else {
  //       setNextSupplierID("SU1"); // Default ID if no suppliers found (without leading zeros)
  //     }
  //   } catch (err) {
  //     console.error("Error fetching suppliers:", err);
  //   }
  // };

  // useEffect(() => {
  //   fetchAndSetNextSupplierID();
  // }, []);

  // const handleSupplierChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormSupplierData({
  //     ...formSupplierData,
  //     [name]: value,
  //   });
  // };

  // // Validate form inputs
  // const validateForm = () => {
  //   const errors = {};
  //   // Required fields validation
  //   if (!formSupplierData.name.trim()) {
  //     errors.name = "Supplier name is required.";
  //   }
  //   if (!formSupplierData.mobile.trim()) {
  //     errors.mobile = "Mobile number is required.";
  //   } else if (!/^01[2-9]\d{8}$/.test(formSupplierData.mobile)) {
  //     errors.mobile = "Mobile number must be 11 digits and start with 01.";
  //   }
  //   if (!formSupplierData.email.trim()) {
  //     errors.email = "Email is required.";
  //   } else if (!/\S+@\S+\.\S+/.test(formSupplierData.email)) {
  //     errors.email = "Email is not valid.";
  //   }
  //   // No validation for optional fields (previous_due and address)
  //   return errors;
  // };

  // const handleSupplierSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsSupplierSubmitting(true);
  //   setError(null);
  //   setSuccessMessage(null);

  //   // Validate form inputs
  //   const errors = validateForm();
  //   if (Object.keys(errors).length > 0) {
  //     setFormSupplierErrors(errors);
  //     setIsSupplierSubmitting(false);
  //     return;
  //   }

  //   try {
  //     // Make API call to add supplier
  //     const response = await addSupplier({
  //       ...formSupplierData,
  //       id: nextSupplierID,
  //     });
  //     Swal.fire("Success", "Supplier added successfully", "success");
  //     setSuccessMessage("Supplier added successfully!");

  //     // Reset form data
  //     setFormSupplierData({
  //       name: "",
  //       mobile: "",
  //       email: "",
  //       brand: "",
  //       tax_number: "",
  //       opening_balance: 0,
  //       address: "",
  //     });
  //     setFormSupplierErrors({});
  //     setIsSupplierModalOpen(false);
  //     setNextSupplierID(""); // Reset Supplier ID before generating the next one
  //     // Generate the next customer ID
  //     await fetchAndSetNextSupplierID(); // Trigger the next customer ID generation without refreshing
  //   } catch (err) {
  //     // Check for validation errors from the API
  //     if (err.response && err.response.status === 401) {
  //       const errors = err.response.data.errors; // Laravel validation errors
  //       setFormSupplierErrors(errors); // Set form errors state for rendering

  //       // Display appropriate error messages in SweetAlert
  //       const errorMessages = [];
  //       if (errors.email) {
  //         errorMessages.push(
  //           "Email is already used, please try another email."
  //         );
  //       }
  //       if (errors.mobile) {
  //         errorMessages.push(
  //           "Mobile number is already used, please try another mobile number."
  //         );
  //       }

  //       Swal.fire({
  //         icon: "error",
  //         title: "Validation Error",
  //         text: errorMessages.join(" "),
  //       });
  //     } else {
  //       // Handle other types of errors
  //       Swal.fire(
  //         "Error",
  //         "Failed to add supplier. Please try again.",
  //         "error"
  //       );
  //       setError("Failed to add supplier. Please try again.");
  //       console.error("Error adding supplier:", err);
  //     }
  //   } finally {
  //     setIsSupplierSubmitting(false);
  //   }
  // };
  return (
    <DashboardLayout>
      {/* {isSupplierModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
          <div className="bg-white px-5 py-4 custom-border rounded-lg shadow-lg w-1/2 ">
            <div className="mb-5 flex justify-between items-center">
              <h2 className="text-lg font-semibold text-primary">
                Add Supplier
              </h2>
              <button
                onClick={() => setIsSupplierModalOpen(false)}
                className="text-primary hover:text-red-500 text-3xl"
              >
                <span>
                  <IoCloseCircleSharp />
                </span>
              </button>
            </div>
            <form
              className="grid grid-cols-2 gap-2  mx-3"
              onSubmit={handleSupplierSubmit}
            >
              {" "}
              <div className=" flex flex-col">
                <label className="text-primary font-semibold mb-1">
                  Supplier ID
                </label>
                <input
                  type="text"
                  name="id"
                  value={nextSupplierID || "Generating..."}
                  className="px-2 py-1.5 border  bg-gray-300 rounded-lg  focus:outline-none"
                  readOnly
                />
              </div>
              <div className="flex flex-col">
                <label className="text-primary font-semibold mb-1">
                  Supplier Name*
                </label>
                <input
                  type="text"
                  name="name"
                  value={formSupplierData.name}
                  onChange={handleSupplierChange}
                  placeholder="Enter Here"
                  className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                  // required
                />
                {formSupplierErrors.name && (
                  <p className="text-red-500 text-sm -mb-1">
                    {formSupplierErrors.name}
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-primary font-semibold mb-1">
                  Mobile No*
                </label>
                <input
                  type="tel"
                  name="mobile"
                  value={formSupplierData.mobile}
                  onChange={handleSupplierChange}
                  placeholder="01xxxxxxxxx"
                  className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                  maxLength="11"
                />

                {formSupplierErrors.mobile && (
                  <p className="text-red-500 text-sm">
                    {Array.isArray(formSupplierErrors.mobile)
                      ? formSupplierErrors.mobile.join(", ")
                      : formSupplierErrors.mobile}
                  </p> // Display mobile errors
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-primary font-semibold mb-1">
                  Email Address*
                </label>
                <input
                  type="email"
                  name="email"
                  value={formSupplierData.email}
                  onChange={handleSupplierChange}
                  placeholder="Enter Here"
                  className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                  // required
                />

                {formSupplierErrors.email && (
                  <p className="text-red-500 text-sm">
                    {Array.isArray(formSupplierErrors.email)
                      ? formSupplierErrors.email.join(", ")
                      : formSupplierErrors.email}
                  </p> // Display email errors
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-primary font-semibold mb-1">Brand</label>
                <input
                  type="text"
                  name="brand"
                  value={formSupplierData.brand}
                  onChange={handleSupplierChange}
                  placeholder="Enter Here"
                  className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                  // required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-primary font-semibold mb-1">
                  Tax Number
                </label>
                <input
                  type="text"
                  name="tax_number"
                  value={formSupplierData.tax_number}
                  onChange={handleSupplierChange}
                  placeholder="Enter Here"
                  className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                  // required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-primary font-semibold mb-1">
                  Opening Balance
                </label>
                <input
                  type="number"
                  name="opening_balance"
                  value={formSupplierData.opening_balance}
                  onChange={handleSupplierChange}
                  placeholder="Enter Here"
                  className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                  //required
                />
              </div>
              <div className="flex flex-col">
                <label className="text-primary font-semibold mb-1">
                  Address
                </label>
                <textarea
                  type="text"
                  name="address"
                  value={formSupplierData.address}
                  onChange={handleSupplierChange}
                  placeholder="Enter Here"
                  className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none h-32"
                  // required
                />
              </div>
              <div className="col-span-full flex justify-center w-full">
                <button
                  type="submit"
                  className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
                  disabled={isSupplierSubmitting}
                >
                  {isSupplierSubmitting ? "Saving..." : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )} */}
    </DashboardLayout>
  );
};

export default PurchaseList;

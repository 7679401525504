import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const DatabaseBackup = () => {
  return (
    <DashboardLayout>
      <div>DatabaseBackup</div>
    </DashboardLayout>
  );
};

export default DatabaseBackup;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const CompanyProfile = () => {
  return (
    <DashboardLayout>
      <div>CompanyProfile</div>
    </DashboardLayout>
  );
};

export default CompanyProfile;

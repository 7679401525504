import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import Loader from "../../components/Loader";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoCloseCircleSharp } from "react-icons/io5";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoIosCopy } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdReceipt } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaFileExcel } from "react-icons/fa6";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { IoIosPrint } from "react-icons/io";
import { FaFileCsv } from "react-icons/fa";
import { HiMiniViewColumns } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchLeafs, addLeaf, editLeaf, deleteLeaf } from "../../api/api";

// PopupReceive component

const LeafSetting = () => {
  useEffect(() => {
    document.title = "Leaf Setting";
  }, []);
  const [actionDropdowns, setActionDropdowns] = useState({});

  const [activeLeaf, setActiveLeaf] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [checkedItems, setCheckedItems] = useState({});
  const [isColumnsDropdownOpen, setIsColumnsDropdownOpen] = useState(false);
  // fetch leaf
  const [leafs, setLeafs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const tableRef = useRef();
  const columnsRef = useRef();

  const navigate = useNavigate(); // Use useNavigate

  const handleEditLeaf = (leaf) => {
    // Navigate to the Editleaf page and pass the leaf data
    navigate(`/editleaf/${leaf.id}`, { state: { leaf } });
  };

  const handleToggleStatus = async (leafId, currentStatus) => {
    const updatedStatus = currentStatus === 1 ? 0 : 1; // Toggle status

    // Optimistically update the UI before the server responds
    setLeafs((prevLeafs) =>
      prevLeafs.map((leaf) =>
        leaf.id === leafId ? { ...leaf, status: updatedStatus } : leaf
      )
    );

    try {
      const response = await editLeaf(leafId, {
        status: updatedStatus,
      });

      // Log the server response for debugging
      console.log("Server response:", response);

      // Optionally, check the server's response and log any issues
      if (response && response.leaf && response.leaf.status !== updatedStatus) {
        console.error("Server did not update the status as expected.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      // Revert the UI change if the request fails
      setLeafs((prevLeafs) =>
        prevLeafs.map((leaf) =>
          leaf.id === leafId ? { ...leaf, status: currentStatus } : leaf
        )
      );
    }
  };

  const toggleActionDropdown = (leafId) => {
    setActionDropdowns((prev) => {
      // Check if the dropdown for the clicked leaf is already open
      const isOpen = prev[leafId] || false;
      // Close all dropdowns and open the one that was clicked
      return { [leafId]: !isOpen };
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (columnsRef.current && !columnsRef.current.contains(event.target)) {
        setIsColumnsDropdownOpen(false);
      }

      if (!event.target.closest(".action-dropdown")) {
        setActionDropdowns({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // add leaf data popup
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    total_number: "",
    status: 1,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    // Disable scrolling when the modal is open
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up function to remove the class if the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isModalOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "status" ? Number(value) : value, // Ensure status is numeric
    });
  };

  const handleLeafSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log("Form Data:", formData);

    try {
      await addLeaf(formData); // Assuming this function sends data to your API
      Swal.fire({
        title: "Success!",
        text: "Leaf has been added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setFormData({
        type: "",
        total_number: "",
        status: 1, // Resetting status to its default value
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Failed to add leaf", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error adding the leaf. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Search Functionality
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Execute the search filtering here
    }, 300); // 300ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // Pagination Logic

  const filteredLeafs = leafs.filter((leaf, index) => {
    const searchTermLower = searchTerm.toLowerCase();

    // Log the current leaf for debugging
    console.log(`Checking leaf: ${JSON.stringify(leaf)}`);

    // Check if the search term is "active" or "inactive"
    if (searchTermLower === "active") {
      return leaf.status === 1; // Only return active leafs
    } else if (searchTermLower === "inactive") {
      return leaf.status === 0; // Only return inactive leafs
    } else {
      // If search term is a number, check against the index + 1 (for serial number)
      const serialNumber = index + 1; // Serial numbers are 1-based
      if (!isNaN(searchTerm) && searchTermLower === serialNumber.toString()) {
        return true; // Return the leaf if the serial number matches
      }

      // Otherwise, check if the leaf name includes the search term
      return leaf.type.toLowerCase().includes(searchTermLower);
    }
  });

  const totalPages = Math.ceil(filteredLeafs.length / itemsPerPage);
  const paginatedLeafs = filteredLeafs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Generate page numbers with ellipsis logic
  const generatePageNumbers = () => {
    const pageNumbers = [];

    // Always show the first page
    pageNumbers.push(1);

    // Check if there are more than two pages
    if (totalPages > 2) {
      // Add ellipses if current page is more than 1 and less than totalPages
      if (currentPage > 1 && currentPage < totalPages) {
        pageNumbers.push("...");
      }

      // Add the current page only if it's not the first or last
      if (currentPage > 1 && currentPage < totalPages) {
        pageNumbers.push(currentPage);
      }

      // Always show the last page if it's not already shown
      if (currentPage < totalPages) {
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  //   // Fetch leafs when the component mounts
  useEffect(() => {
    const getLeafs = async () => {
      try {
        setLoading(true); // Set loading to true before fetching
        const leafData = await fetchLeafs();
        // Sort leafs by 'created_at' in descending order (newest first)
        const sortedLeafs = leafData.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setLeafs(leafData); // Set the leafs into state
      } catch (err) {
        setError(err); // Handle the error
      } finally {
        setLoading(false); // Stop the loading spinner
      }
    };

    getLeafs(); // Trigger the fetch
  }, []);

  const handleDeleteLeaf = async () => {
    // Convert selectedLeafIds to numbers
    const selectedLeafIds = Object.keys(checkedItems)
      .filter((id) => checkedItems[id])
      .map(Number); // Ensure IDs are numbers

    if (selectedLeafIds.length === 0) {
      Swal.fire(
        "No Selection",
        "Please select at least one leaf to delete.",
        "info"
      );
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text:
        selectedLeafIds.length === 1
          ? "Do you want to delete this leaf?"
          : `Do you want to delete ${selectedLeafIds.length} leafs?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Show a loading indicator
          Swal.fire({
            title: "Deleting...",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          // Perform deletion for each selected leaf
          const deletePromises = selectedLeafIds.map((id) => deleteLeaf(id));

          await Promise.all(deletePromises);

          // After successful deletion, show success message
          Swal.fire(
            "Deleted!",
            selectedLeafIds.length === 1
              ? "The selected leaf has been deleted."
              : "The selected leafs have been deleted.",
            "success"
          );

          // ** Update the leafs state by removing deleted leafs **
          setLeafs((prevLeafs) => {
            return prevLeafs.filter(
              (leaf) => !selectedLeafIds.includes(leaf.id)
            );
          });

          // Clear checked items after deletion
          setCheckedItems((prevChecked) => {
            const updatedChecked = { ...prevChecked };
            selectedLeafIds.forEach((id) => {
              delete updatedChecked[id];
            });
            return updatedChecked;
          });
        } catch (error) {
          console.error("Deletion error:", error);
          Swal.fire(
            "Error!",
            "There was an error deleting the selected leafs.",
            "error"
          );
        }
      }
    });
  };

  const handleDeleteSingleLeaf = async (leafId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this leaf?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Show a loading indicator
          Swal.fire({
            title: "Deleting...",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          await deleteLeaf(leafId);

          // After successful deletion, show success message
          Swal.fire(
            "Deleted!",
            "The selected leaf has been deleted.",
            "success"
          );

          // Update the leafs state by removing the deleted leaf
          setLeafs((prevLeafs) =>
            prevLeafs.filter((leaf) => leaf.id !== leafId)
          );

          // Remove the leaf from checkedItems if it was checked
          setCheckedItems((prevChecked) => {
            const updatedChecked = { ...prevChecked };
            delete updatedChecked[leafId];
            return updatedChecked;
          });
        } catch (error) {
          console.error("Deletion error:", error);
          Swal.fire(
            "Error!",
            "There was an error deleting the selected leaf.",
            "error"
          );
        }
      }
    });
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    const newCheckedItems = {};
    leafs.forEach((leaf) => {
      newCheckedItems[leaf.id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  const handleIndividualCheckbox = (leafId) => {
    setCheckedItems((prevChecked) => ({
      ...prevChecked,
      [leafId]: !prevChecked[leafId],
    }));
  };

  const isDeleteButtonVisible = Object.values(checkedItems).some(Boolean);
  // If loading, show a loading message
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  // If there is an error, display the error message
  if (error) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-primary">Leaf List</h2>
          <div>
            <p
              className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              <span>
                <BsFillPlusCircleFill />
              </span>
              Add Leaf
            </p>
            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
                <div className="bg-white px-5 py-4 custom-border rounded-lg shadow-lg w-full max-w-md ">
                  <div className="mb-5 flex justify-between items-center">
                    <h2 className="text-lg font-semibold text-primary">
                      Add Leaf
                    </h2>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="text-primary hover:text-red-500 text-3xl"
                    >
                      <span>
                        <IoCloseCircleSharp />
                      </span>
                    </button>
                  </div>
                  <form className="grid gap-5 px-5" onSubmit={handleLeafSubmit}>
                    <div className="flex flex-col">
                      <label className="text-primary font-semibold mb-1">
                        Leaf Type*
                      </label>
                      <input
                        type="text"
                        name="type"
                        value={formData.type}
                        onChange={handleInputChange}
                        placeholder="Enter Leaf Type"
                        className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="text-primary font-semibold mb-1">
                        Total Number*
                      </label>
                      <input
                        type="number"
                        name="total_number"
                        value={formData.total_number}
                        onChange={handleInputChange}
                        placeholder="Enter Total Number"
                        className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                        required
                      />
                    </div>
                    <div className="flex gap-3 items-center mt-1">
                      <p className="text-primary font-semibold">Status*</p>
                      <div className="flex items-center">
                        <input
                          id="active"
                          type="radio"
                          value={1}
                          name="status"
                          checked={formData.status === 1}
                          onChange={handleInputChange}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-600"
                        />
                        <label
                          htmlFor="active"
                          className="ms-2 text-sm font-medium text-primary"
                        >
                          Active
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="inactive"
                          type="radio"
                          value={0}
                          name="status"
                          checked={formData.status === 0}
                          onChange={handleInputChange}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-600"
                        />
                        <label
                          htmlFor="inactive"
                          className="ms-2 text-sm font-medium text-primary"
                        >
                          Inactive
                        </label>
                      </div>
                    </div>
                    <div className="flex justify-center w-full">
                      <button
                        type="submit"
                        className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
                      >
                        {isSubmitting ? "Saving..." : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Table Controls */}
        <div className="flex justify-between items-center mb-4 dropdown">
          <div className="flex justify-between items-center gap-3 font-semibold text-primary">
            <p>Show</p>
            <select
              className="flex  items-center border custom-border w-24 px-1 py-1 focus:outline-none cursor-pointer"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange} // Made dynamic
            >
              <option
                value={5}
                className="hover:bg-white cursor-pointer custom-border"
              >
                5
              </option>
              <option
                value={10}
                className="hover:bg-white cursor-pointer custom-border"
              >
                10
              </option>
              <option
                value={15}
                className="hover:bg-white cursor-pointer custom-border"
              >
                15
              </option>
            </select>
          </div>
          <div className="w-24">
            {isDeleteButtonVisible && (
              <div
                className="flex justify-between text-center items-center bg-red-600 custom-border px-2 py-1"
                onClick={handleDeleteLeaf}
              >
                <button className=" text-white hover:text-gray-300 py-1 px-2 cursor-pointer  flex items-center gap-1">
                  Delete{" "}
                  <span>
                    <RiDeleteBin6Fill />
                  </span>
                </button>
              </div>
            )}
          </div>

          <div className="flex justify-between text-center items-center bg-primary custom-border px-2 py-1">
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              Copy{" "}
              <span>
                <IoIosCopy />
              </span>
            </button>
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              Excel{" "}
              <span>
                <FaFileExcel />
              </span>
            </button>
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              PDF{" "}
              <span>
                <BsFillFileEarmarkPdfFill />
              </span>
            </button>
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              Print{" "}
              <span>
                <IoIosPrint />
              </span>
            </button>

            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              CSV{" "}
              <span>
                <FaFileCsv />
              </span>
            </button>

            <button
              ref={columnsRef}
              className="relative  text-white  py-1 px-2 cursor-pointer flex items-center gap-1 "
            >
              <span className="hover:text-secondary">Columns</span>
              <span>
                <HiMiniViewColumns />
              </span>
            </button>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="border custom-border bg-gray-300 text-primary font-semibold rounded-md p-2 focus:outline-none pl-8 focus:ring-0 focus:ring-blue-500"
              value={searchTerm}
              onChange={handleSearch}
            />
            <span className="absolute left-2 top-3 text-xl text-primary font-bold">
              <CiSearch />
            </span>
          </div>
        </div>
        {/* Table */}
        <table
          ref={tableRef}
          className="bg-white border border-gray-200 w-full"
        >
          <thead>
            <tr className="bg-primary text-white text-sm">
              <th className="py-1 px-2 border-r w-6 ">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={
                    leafs.length > 0 &&
                    Object.keys(checkedItems).length === leafs.length &&
                    Object.values(checkedItems).every(Boolean)
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th className="py-1 px-2 border-r">SL</th>
              <th className="p-1 border-r">Leaf Type</th>
              <th className="p-1 border-r">Total Number</th>
              <th className="p-1 border-r text-center">Status</th>
              <th className="p-1 border-r text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedLeafs.length > 0 ? (
              paginatedLeafs.map((leaf, index) => (
                <tr key={leaf.id} className="border-b">
                  <td className="py-1 px-2 text-center border-r">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={!!checkedItems[leaf.id]}
                      onChange={() => handleIndividualCheckbox(leaf.id)}
                    />
                  </td>
                  <td className="p-1 text-gray-600 text-center border-r">
                    {index + 1}
                    {/* {(currentPage - 1) * itemsPerPage + index + 1} */}
                  </td>{" "}
                  <td className="p-1 border-r text-gray-600">{leaf.type}</td>
                  <td className="p-1 border-r text-gray-600 text-center">
                    {leaf.total_number}
                  </td>
                  <td className="p-1 border-r  text-center">
                    <span
                      className={`py-1 px-2 rounded text-white text-xs cursor-pointer ${
                        leaf.status === 1 ? "bg-green-500" : "bg-red-500"
                      }`}
                      onClick={() => handleToggleStatus(leaf.id, leaf.status)}
                    >
                      {leaf.status === 1 ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td className="p-1 text-center action-dropdown">
                    <div className="flex justify-center">
                      <div className="relative">
                        <button
                          className="bg-primary text-sm text-white py-1 px-2 gap-1 rounded-md hover:bg-secondary flex justify-center items-center"
                          onClick={() => toggleActionDropdown(leaf.id)}
                        >
                          Action
                          {actionDropdowns[leaf.id] ? (
                            <IoIosArrowUp />
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </button>
                        {actionDropdowns[leaf.id] && (
                          <div className="absolute bg-primary custom-border right-0 w-48 z-10 p-2 text-start text-white text-sm list-none">
                            <ul>
                              <li
                                className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                onClick={() => handleEditLeaf(leaf)}
                              >
                                <FaEdit /> Edit
                              </li>

                              <li
                                className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                onClick={() => handleDeleteSingleLeaf(leaf.id)}
                              >
                                <RiDeleteBin6Fill /> Delete
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="11"
                  className="py-4 px-4 text-center text-red-500 font-semibold"
                >
                  Sorry, No leafs found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex justify-between items-center mt-4 ">
          <p className="text-primary font-semibold">
            Showing{" "}
            {filteredLeafs.length > 0
              ? (currentPage - 1) * itemsPerPage + 1
              : 0}{" "}
            to{" "}
            {currentPage * itemsPerPage > filteredLeafs.length
              ? filteredLeafs.length
              : currentPage * itemsPerPage}{" "}
            of {filteredLeafs.length} entries
          </p>
          <div className="flex justify-end">
            <div className="flex justify-between items-center w-full bg-blue-200 custom-border px-4 py-1 space-x-2  mx-auto">
              {/* Previous Button */}
              <button
                className={`px-3 py-1 custom-border ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                    : "bg-blue-200 text-primary font-semibold hover:bg-blue-300"
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              {/* Generate Page Numbers with Ellipses */}
              {generatePageNumbers().map((page, index) => (
                <button
                  key={index}
                  className={`px-7 py-1 font-semibold custom-border ${
                    currentPage === page
                      ? "bg-primary text-white"
                      : "bg-blue-200 text-primary hover:bg-blue-300"
                  }`}
                  onClick={() =>
                    typeof page === "number" && handlePageChange(page)
                  }
                  disabled={typeof page !== "number"} // Disable ellipses
                >
                  {page}
                </button>
              ))}

              {/* Next Button */}
              <button
                className={`px-3 py-1 custom-border ${
                  currentPage === totalPages || totalPages === 0
                    ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                    : "bg-blue-200 text-primary font-semibold hover:bg-blue-300"
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 0}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default LeafSetting;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const UserList = () => {
  return (
    <DashboardLayout>
      <div>UserList</div>
    </DashboardLayout>
  );
};

export default UserList;

import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

const PaymentTypeList = () => {
  return (
    <DashboardLayout>
      <div>PaymentTypeList</div>
    </DashboardLayout>
  );
};

export default PaymentTypeList;
